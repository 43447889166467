import { Language, LanguageType } from '@nx-smartmonkey/shared/domain';

export enum TranslationsActionType {
  SET_TRANSLATIONS = `SET_TRANSLATIONS`,
  SET_ACTIVE_LANGUAGE = `SET_ACTIVE_LANGUAGE`,
}

export interface SetTranslationsAction {
  type: TranslationsActionType.SET_TRANSLATIONS;
  payload: {
    translations: { language: LanguageType; translations: Record<string, string> }[];
  };
}

export interface SetActiveLanguageAction {
  type: TranslationsActionType.SET_ACTIVE_LANGUAGE;
  payload: {
    language: Language;
  };
}

export type TranslationsActions = SetTranslationsAction | SetActiveLanguageAction;
