import { RoutalPalette } from '@nx-smartmonkey/ui';
import { StopStatus } from '../domain/stop/StopStatus';

export const STATUS_COLOR_LIGHTEN_AMOUNT = 35;

export const getStopColorByStatus = (status: StopStatus) => {
  switch (status) {
    case `completed`:
      return RoutalPalette.statusColors.completed;
    case `incomplete`:
      return RoutalPalette.statusColors.incomplete;
    case `canceled`:
      return RoutalPalette.statusColors.canceled;
    default:
      return RoutalPalette.statusColors.pending;
  }
};

type StopStatusBarTypes = {
  status: StopStatus;
};

export const StopStatusBar = ({ status }: StopStatusBarTypes) => {
  return (
    <div
      style={{
        width: `4px`,
        height: `100%`,
        backgroundColor: getStopColorByStatus(status),
        borderRadius: `4px`,
      }}
    />
  );
};
