import { ValueObject } from './ValueObject';

export type LanguageType = `en` | `es` | `pt`;

export const validLanguages: LanguageType[] = [`en`, `es`, `pt`];

export interface LanguageProps {
  value: LanguageType;
}

export class Language extends ValueObject<LanguageProps> {
  get value(): LanguageType {
    return this.props.value;
  }

  private constructor(props: LanguageProps) {
    super(props);
  }

  private static isValidLanguage(language: LanguageType) {
    return validLanguages.indexOf(language) !== -1;
  }

  static create(value: LanguageType): Language {
    if (!this.isValidLanguage(value)) {
      throw new Error(`Language ${value} is not valid`);
    } else {
      return new Language({ value });
    }
  }
}
