import { LanguageType } from '@nx-smartmonkey/shared/domain';
import { translationsRepository } from '../repositories/translations';
import { TranslationsRepository } from '../repositories/translations/TranslationsRepository';

export class TranslationsService {
  constructor(private translationsRepository: TranslationsRepository) {}

  async getTranslations({
    languages,
  }: {
    languages: LanguageType[];
  }): Promise<{ language: LanguageType; translations: Record<string, string> }[]> {
    return this.translationsRepository.getTranslations({ languages });
  }
}

export const translationsService = new TranslationsService(translationsRepository);
