import { Grid } from '@mui/material';
import { Report } from '@nx-smartmonkey/shared/domain';
import { dateFormat } from '@nx-smartmonkey/shared/helpers';
import { OSMap, OSMarker, RoutalDialog, RoutalLoader, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import React, { useEffect, useState } from 'react';

import NoReportLocationImg from '../../../assets/img/no_report_location_placeholder.jpg';
import CrossIcon from '../../../assets/svg/CrossIcon';
import ZoomIcon from '../../../assets/svg/ZoomIcon';
import { getStopColorByStatus } from '../../../components/StopStatusBar';
import { CustomFields } from '../../../components/custom-fields/CustomFields';
import { Project } from '../../../domain/project/Project';
import { Stop } from '../../../domain/stop/Stop';
import { Supervisor } from '../../../domain/supervisor/Supervisor';
import { Text } from '../../../react-components/Text';
import { InfoContainer } from './RightPanel.styled';

interface Coordinate {
  lat: number;
  lng: number;
}

const distanceBetweenKM = ({ coord1, coord2 }: { coord1: Coordinate; coord2: Coordinate }) => {
  const Earth = 6371; // Radius of the Earth in km
  const x = (((coord2.lng - coord1.lng) * Math.PI) / 180) * Math.cos((((coord1.lat + coord2.lat) / 2) * Math.PI) / 180);
  const y = ((coord2.lat - coord1.lat) * Math.PI) / 180;
  return Earth * Math.sqrt(x * x + y * y);
};

interface ImageContainerProps {
  key: string;
  src: string;
  alt?: string;
  style?: React.CSSProperties;
}

const ImageContainer = ({ key, src, alt = ``, style = {} }: ImageContainerProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [zoomImageSrc, setZoomImageSrc] = useState<string | undefined>(undefined);

  return (
    <>
      <div
        key={key}
        style={{
          position: `relative`,
          borderRadius: `4px`,
          padding: `4px`,
          ...style,
          borderColor: `${isHover ? RoutalPalette.primary40 : `${RoutalPalette.grey7}`}`,
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <img width={style?.width ?? `auto`} height={style?.height ?? `auto`} src={src} alt={alt} draggable="false" />
        <div
          style={{
            cursor: `pointer`,
            position: `absolute`,
            left: 0,
            top: 0,
            width: `100%`,
            height: `100%`,
            backgroundColor: `${isHover ? `${RoutalPalette.primary40}99` : `transparent`}`,
            borderRadius: `4px`,
          }}
          onClick={() => setZoomImageSrc(src)}
        >
          <ZoomIcon
            style={{
              width: `40px`,
              height: `40px`,
              color: RoutalPalette.neutral00,
              position: `absolute`,
              left: `calc(50% - 20px)`,
              top: `calc(50% - 20px)`,
              display: `${isHover ? `block` : `none`}`,
            }}
          />
        </div>
      </div>

      <RoutalDialog
        open={Boolean(zoomImageSrc)}
        dialogProps={{
          maxWidth: `md`,
        }}
        onCloseDialog={() => setZoomImageSrc(undefined)}
        showFooter={false}
        showHeader={false}
        contentStyles={{
          overflow: `hidden`,
        }}
      >
        <Grid container>
          <div
            style={{
              position: `absolute`,
              top: `6px`,
              right: `10px`,
              cursor: `pointer`,
              width: `30px`,
              height: `30px`,
              backgroundColor: RoutalPalette.grey3,
              borderRadius: `50%`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
            onClick={() => setZoomImageSrc(undefined)}
          >
            <CrossIcon style={{ color: RoutalPalette.neutral00, width: `20px`, height: `20px` }} />
          </div>

          <Grid container justifyContent="center" alignItems="center">
            <img
              alt=""
              src={zoomImageSrc}
              style={{
                width: `auto`,
                height: `auto`,
                maxWidth: `100%`,
                maxHeight: `calc(90vh - 12px)`,
                paddingBottom: `12px`,
                minWidth: `220px`,
              }}
            />
          </Grid>
        </Grid>
      </RoutalDialog>
    </>
  );
};

export const getReportStatusColor = (report: Report) => {
  let color;
  switch (report.type) {
    case `service_report_completed`:
      color = getStopColorByStatus(`completed`);
      // TASKS NOT VISIBLE IN SUPERVISORS FOR THE MOMENT
      // if ((report.tasks ?? []).length > 0) {
      //   if (report.tasks!.some((task) => task.status !== `completed`)) {
      //     color = getStopColorByStatus(`incomplete`);
      //   }
      // }
      break;
    case `service_report_canceled`:
      color = getStopColorByStatus(`canceled`);
      break;
  }
  return color;
};

type StopReportProps = {
  stop: Stop;
  project: Project;
  supervisor: Supervisor;
};

const StopReport = ({ stop, project, supervisor }: StopReportProps) => {
  useEffect(() => {
    if (stop.report?.location?.lat && stop.report?.location?.lng) {
      // Removes the Leaf Let bottom text.
      const elements = document.querySelectorAll(`[class="leaflet-bottom leaflet-right"]`);
      (elements ?? []).forEach((el) => {
        (el as any).style.visibility = `hidden`;
      });
    }
  }, [stop.report, stop.driver_info?.position]);

  const cancelReasons = [
    {
      translationId: `stop.report.reason.nobody`,
      tag: `nobody`,
    },
    {
      translationId: `stop.report.reason.wrong_address`,
      tag: `wrong_address`,
    },
    {
      translationId: `stop.report.reason.missing_information`,
      tag: `missing_information`,
    },
    {
      translationId: `stop.report.reason.other`,
      tag: `other`,
    },
  ];

  const getReportStatusText = (report: Report) => {
    let status;
    let color;
    switch (report.type) {
      case `service_report_completed`:
        status = `completed`;
        color = RoutalPalette.statusColors.completed;
        // TASKS NOT VISIBLE IN SUPERVISORS FOR THE MOMENT
        // if ((report.tasks ?? []).length > 0) {
        //   if (report.tasks!.some((task) => task.status !== `completed`)) {
        //     status = `incomplete`;
        //     color = `#FFB300`;
        //   }
        // }
        break;
      case `service_report_canceled`:
        status = `canceled`;
        color = RoutalPalette.statusColors.canceled;
        break;
    }
    return (
      <Text intlId={`stop.report.status.${status}`} style={{ textTransform: `uppercase`, fontWeight: 600, color }} />
    );
  };

  const getReportDistanceKM = (reportLocation: { lat: number; lng: number }) => {
    if (stop.location?.lat && stop.location?.lng) {
      const distance = distanceBetweenKM({
        coord1: { lat: stop.location!.lat, lng: stop.location!.lng },
        coord2: reportLocation,
      });
      return distance === Math.floor(distance) ? distance : distance.toFixed(2);
    }
    return 0;
  };

  if (!stop.report) {
    return null;
  }

  return (
    <>
      {(supervisor.isStopCompletedFieldEnabled(`location`) || supervisor.isStopCanceledFieldEnabled(`location`)) &&
      stop.report.location?.isValid() ? (
        <>
          <VSpacer medium />

          <Grid item style={{ width: `100%`, padding: `0`, borderRadius: `20px` }}>
            <OSMap
              loadingComponent={<RoutalLoader width={60} />}
              key="map"
              height="140px"
              zoomControl
              scrollWheelZoom
              dragging
            >
              {stop.location?.isValid() ? (
                <OSMarker
                  id={stop.id}
                  key={stop.id}
                  lat={stop.location!.lat!}
                  lng={stop.location!.lng!}
                  pointer
                  color={stop.driver_info?.color?.value}
                />
              ) : null}

              <OSMarker
                id={stop.report.id}
                key={stop.report.id}
                lat={stop.report.location!.lat!}
                lng={stop.report.location!.lng!}
                color={stop.driver_info?.color?.value ? stop.driver_info?.color?.value : RoutalPalette.routeBaseColor}
                kind="routal_truck"
              />
            </OSMap>
            <Grid item style={{ textAlign: `center` }}>
              <Text
                style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }}
                intlId="stop.report.location_distance"
                intlValues={{
                  kms: getReportDistanceKM({ lat: stop.report.location!.lat!, lng: stop.report.location!.lng! }),
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ position: `relative`, height: `140px` }}>
          <div
            style={{
              width: `100%`,
              height: `140px`,
              position: `absolute`,
              top: 0,
              left: 0,
              backgroundImage: `url(${NoReportLocationImg})`,
              backgroundSize: `cover`,
              filter: `brightness(0.5)`,
            }}
          />
          <Grid
            style={{ height: `140px`, padding: `0 24px` }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Text
                isBold
                style={{ position: `relative`, textAlign: `center`, zIndex: 2, color: RoutalPalette.neutral00 }}
                variant="h5"
                intlId="stop.report.no_location_title"
              />
            </Grid>
            <VSpacer medium />
            <Grid item>
              <Text
                style={{ position: `relative`, textAlign: `center`, zIndex: 2, color: RoutalPalette.neutral00 }}
                intlId="stop.report.no_location_description"
              />
            </Grid>
          </Grid>
        </div>
      )}

      <InfoContainer>
        <Text style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }} intlId="stop.report.status" />
        <VSpacer small />
        {getReportStatusText(stop.report)}

        <VSpacer small />

        {((stop.report.type === `service_report_completed` && supervisor.isStopCompletedFieldEnabled(`created_at`)) ||
          (stop.report.type === `service_report_canceled` && supervisor.isStopCanceledFieldEnabled(`created_at`))) && (
          <>
            <Text style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }} intlId="stop.report.date_time" />
            <VSpacer small />
            {dateFormat(stop.report.created_at)}

            <VSpacer small />
          </>
        )}

        {/* {drivers.configuration.includes(`stop_duration`) && (
          <>
            <Text
              style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }}
              intlId="stop.report.time_in_stop"
            />
            <VSpacer small />
            {currentReport.time_in_stop !== undefined ? (
              <Text>{secondsToTime(currentReport.time_in_stop)}</Text>
            ) : (
              <Text intlId="none" />
            )}
            <VSpacer small />
          </>
        )} */}

        {((stop.report.type === `service_report_completed` && supervisor.isStopCompletedFieldEnabled(`images`)) ||
          (stop.report.type === `service_report_canceled` && supervisor.isStopCanceledFieldEnabled(`images`))) && (
          <>
            <Text style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }} intlId="stop.report.images" />
            <VSpacer small />
            <Grid container>
              {(stop.report.images ?? []).length > 0 ? (
                stop.report.images!.map((image: { id: string; url: string }) => (
                  <ImageContainer
                    key={image.id}
                    src={`${image.url}/public?public_key=${project?.public_key}`}
                    alt={`Report image ${image.id}`}
                    style={{
                      width: `64px`,
                      height: `64px`,
                    }}
                  />
                ))
              ) : (
                <p style={{ marginTop: `0px` }}>
                  <Text intlId="none" />
                </p>
              )}
            </Grid>

            <VSpacer small />
          </>
        )}

        {stop.report.type === `service_report_completed` && supervisor.isStopCompletedFieldEnabled(`signature`) && (
          <>
            <Text style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }} intlId="stop.report.signature" />
            <VSpacer small />
            {stop.report.signature ? (
              <ImageContainer
                key="report-complete-signature"
                src={`${stop.report.signature.url}/public?public_key=${project.public_key}`}
                alt="Report signature"
                style={{
                  width: `122px`,
                  border: `2px dashed ${RoutalPalette.neutral40}`,
                }}
              />
            ) : (
              <p style={{ marginTop: `0px` }}>
                <Text intlId="none" />
              </p>
            )}

            <VSpacer small />
          </>
        )}

        {stop.report.type === `service_report_canceled` && supervisor.isStopCanceledFieldEnabled(`cancel_reason`) && (
          <>
            <Text style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }} intlId="stop.report.reason" />
            <VSpacer small />
            <p style={{ marginTop: `0px` }}>
              {stop.report.cancel_reason ? (
                (() => {
                  const foundReason = cancelReasons.find(
                    (cancelReason: any) => cancelReason.tag === stop.report!.cancel_reason
                  );
                  return foundReason ? <Text intlId={`${foundReason.translationId}`} /> : null;
                })()
              ) : (
                <p style={{ marginTop: `0px` }}>
                  <Text intlId="none" />
                </p>
              )}
            </p>
          </>
        )}

        {((stop.report.type === `service_report_completed` && supervisor.isStopCompletedFieldEnabled(`comments`)) ||
          (stop.report.type === `service_report_canceled` && supervisor.isStopCanceledFieldEnabled(`comments`))) && (
          <>
            <Text style={{ cursor: `default`, color: `${RoutalPalette.grey7}` }} intlId="stop.report.comments" />
            <VSpacer small />
            <p style={{ marginTop: `0px` }}>
              {stop.report.comments ? (
                <Text>{stop.report.comments}</Text>
              ) : (
                <span style={{ fontSize: `12px` }}>
                  <Text intlId="none" />
                </span>
              )}
            </p>
            <VSpacer small />
          </>
        )}

        {project.custom_fields && stop.report.custom_fields ? (
          <>
            {project.custom_fields.service_report_completed.length > 0 &&
            stop.report.type === `service_report_completed` ? (
              <CustomFields
                readOnly
                customFields={stop.report.custom_fields}
                projectCustomFields={project.custom_fields.service_report_completed.filter((field) =>
                  Object.keys(stop.report!.custom_fields!.value)
                    .map((key) => key)
                    .includes(field.custom_id)
                )}
              />
            ) : null}

            {project.custom_fields.service_report_canceled.length > 0 &&
            stop.report.type === `service_report_canceled` ? (
              <CustomFields
                readOnly
                customFields={stop.report.custom_fields}
                projectCustomFields={project.custom_fields.service_report_canceled.filter((field) =>
                  Object.keys(stop.report!.custom_fields!.value)
                    .map((key) => key)
                    .includes(field.custom_id)
                )}
              />
            ) : null}
            <VSpacer small />
          </>
        ) : null}
      </InfoContainer>
    </>
  );
};

export default StopReport;
