import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Chip, Grid, Tooltip } from '@mui/material';
import { CustomFieldDefinition, CustomFieldValues } from '@nx-smartmonkey/shared/domain';
import {
  calculateDuration,
  dateFormat,
  dateFormatShortTime,
  parsePhoneNumber,
  parseSecondsToHM,
  pastDateTime,
} from '@nx-smartmonkey/shared/helpers';
import { TimeWindow } from '@nx-smartmonkey/shared/interfaces';
import { RoutalPalette, RoutalTooltip } from '@nx-smartmonkey/ui';

import { RatingStars } from '../../components/RatingStars';
import { getStopColorByStatus } from '../../components/StopStatusBar';
import { customFieldToTableValue } from '../../components/TableColumnsButton';
import {
  CUSTOM_FIELD_COLUMN_ID_PREFIX,
  REPORT_CANCELED_CUSTOM_FIELD_COLUMN_ID_PREFIX,
  REPORT_COMPLETED_CUSTOM_FIELD_COLUMN_ID_PREFIX,
} from '../../constants';
import { StopsTableColumn, StopsTableColumnKeys } from '../../context/stops/tableColumns';
import { Project } from '../../domain/project/Project';
import { Stop } from '../../domain/stop/Stop';
import { StopStatus } from '../../domain/stop/StopStatus';
import { Supervisor } from '../../domain/supervisor/Supervisor';
import { intl } from '../../helpers/IntGlobalProvider';
import { DetailTabTypes } from '../../hooks/right-panel/useRetrieveRightPanelInfo';
import { Button } from '../../react-components/Button';
import { Text } from '../../react-components/Text';
import { AlignmentTypes, DataTableColumnProps } from '../../react-components/data-table/DataTable';
import configuration from '../../services/configuration';

type StopsColumnsResponse = { [key: string]: any }[];

type StopsGetColumns = {
  project: Project;
  supervisor: Supervisor;
  tableColumns: Array<StopsTableColumn>;
  onOpenRightPanel?: (e: any, stop: Stop, detailTab?: DetailTabTypes) => void;
};

export const getStopsColumns = ({
  project,
  supervisor,
  tableColumns,
  onOpenRightPanel,
}: StopsGetColumns): StopsColumnsResponse => {
  const columns: Array<DataTableColumnProps> = [];

  const stopCustomFieldDefinitionsMap: Record<string, CustomFieldDefinition> = (project.custom_fields?.client ?? [])
    .filter((customFieldDefinition) => customFieldDefinition.enabled)
    .reduce((amount, customFieldDefinition) => {
      return {
        ...amount,
        [`${CUSTOM_FIELD_COLUMN_ID_PREFIX}${customFieldDefinition.custom_id}`]: customFieldDefinition,
      };
    }, {});

  const reportCompletedCustomFieldDefinitionsMap: Record<string, CustomFieldDefinition> = (
    project.custom_fields?.service_report_completed ?? []
  )
    .filter((customFieldDefinition) => customFieldDefinition.enabled)
    .reduce((amount, customFieldDefinition) => {
      return {
        ...amount,
        [`${REPORT_COMPLETED_CUSTOM_FIELD_COLUMN_ID_PREFIX}${customFieldDefinition.custom_id}`]: customFieldDefinition,
      };
    }, {});

  const reportCanceledCustomFieldDefinitionsMap: Record<string, CustomFieldDefinition> = (
    project.custom_fields?.service_report_canceled ?? []
  )
    .filter((customFieldDefinition) => customFieldDefinition.enabled)
    .reduce((amount, customFieldDefinition) => {
      return {
        ...amount,
        [`${REPORT_CANCELED_CUSTOM_FIELD_COLUMN_ID_PREFIX}${customFieldDefinition.custom_id}`]: customFieldDefinition,
      };
    }, {});

  tableColumns.forEach(({ displayable, identifier, sortable }: StopsTableColumn) => {
    if (displayable) {
      switch (identifier) {
        case `label`:
          if (supervisor.isStopFieldEnabled(`label`)) {
            columns.push({
              width: 220,
              accessorName: `label`,
              name: <Text variant="label" intlId="stop.label" />,
              sortable,
              formatter: ({ value: label }) => (
                <>
                  {label && label !== `` ? (
                    <RoutalTooltip title={label}>
                      <Text variant="body" style={{ fontWeight: 500 }}>
                        {label.length > 30 ? `${label.substring(0, 30)}...` : `${label}`}
                      </Text>
                    </RoutalTooltip>
                  ) : (
                    <Text
                      variant="body"
                      style={{ fontWeight: 500, color: `${RoutalPalette.secondary20}` }}
                      intlId="no_name"
                    />
                  )}
                </>
              ),
            });
          }
          break;
        case `vehicle_label`:
          if (supervisor.isStopFieldEnabled(`driver_info`)) {
            columns.push({
              accessorName: `vehicle_label`,
              width: 220,
              name: <Text variant="label" intlId="stop.vehicle_label" />,
              sortable,
              formatter: ({ doc: stop }: { doc: Stop }) => {
                if (!stop.driver_info?.label) {
                  return null;
                }

                return (
                  <Text variant="body" style={{ fontWeight: 500 }}>
                    {stop.driver_info.label}
                  </Text>
                );
              },
            });
          }
          break;
        case `survey`:
          if (supervisor.isSurveyFieldEnabled(`rating`)) {
            columns.push({
              width: 135,
              accessorName: `survey`,
              align: `center` as AlignmentTypes,
              name: <Text variant="label" intlId="stop.survey" />,
              formatter: ({ doc }) => {
                if (!doc.survey) {
                  return null;
                }
                return <RatingStars rating={doc.survey?.rating ?? 0} justifyContent="center" />;
              },
            });
          }
          break;
        case `status`:
          columns.push({
            width: 135,
            accessorName: `status`,
            align: `center` as AlignmentTypes,
            sortable,
            name: <Text variant="label" intlId="stop.status" />,
            formatter: ({ value: status }: { value: StopStatus }) =>
              status && (
                <Grid container alignItems="center">
                  <Chip
                    style={{
                      backgroundColor: getStopColorByStatus(status),
                      width: `299px`,
                      padding: `0 12px`,
                    }}
                    label={
                      <Text
                        variant="body"
                        intlId={`stop.status.${status}`}
                        style={{ color: RoutalPalette.neutral00 }}
                      />
                    }
                  />
                </Grid>
              ),
          });
          break;
        case `type`:
          columns.push({
            width: 135,
            accessorName: `type`,
            align: `center` as AlignmentTypes,
            sortable,
            name: <Text variant="label" intlId="stop.type" />,
            formatter: ({ value: type }) => (
              <Text variant="body" style={{ fontWeight: 500 }} intlId={`stop.type.${type}`} />
            ),
          });
          break;
        case `report_location`:
          if (supervisor.isStopCompletedFieldEnabled(`location`) || supervisor.isStopCanceledFieldEnabled(`location`)) {
            columns.push({
              width: 244,
              accessorName: `report_location`,
              sortable,
              name: <Text variant="label" intlId="stop.report_location" />,
              formatter: ({ doc }: { doc: Stop }) => {
                const reportLocation = doc.report?.location;

                if (reportLocation?.lat && reportLocation?.lng) {
                  return (
                    <span
                      style={{
                        cursor: `pointer`,
                        color: RoutalPalette.primary40,
                        fontFamily: `Geist Variable`,
                        marginLeft: `4px`,
                        border: `none`,
                      }}
                      onClick={(e) => {
                        window.open(
                          `https://maps.google.com/maps?daddr=${reportLocation.lat},${reportLocation.lng}&amp;ll=`
                        );
                      }}
                    >
                      {reportLocation.lat}, {reportLocation.lng}
                    </span>
                  );
                }

                return <span>-</span>;
              },
            });
          }
          break;
        case `report_created_at`:
          if (
            supervisor.isStopCompletedFieldEnabled(`created_at`) ||
            supervisor.isStopCanceledFieldEnabled(`created_at`)
          ) {
            columns.push({
              width: 244,
              accessorName: `report_created_at`,
              sortable,
              align: `center` as AlignmentTypes,
              name: <Text variant="label" intlId="stop.report_created_at" />,
              formatter: ({ doc }: { doc: Stop }) => {
                const reportCreatedAt = doc.report?.created_at;

                if (reportCreatedAt) {
                  return (
                    <RoutalTooltip title={dateFormat(reportCreatedAt)}>
                      <Text variant="body">{pastDateTime(reportCreatedAt)}</Text>
                    </RoutalTooltip>
                  );
                }

                return <span>-</span>;
              },
            });
          }
          break;
        case `report_comments`:
          if (supervisor.isStopCompletedFieldEnabled(`comments`) || supervisor.isStopCanceledFieldEnabled(`comments`)) {
            columns.push({
              width: 160,
              accessorName: identifier,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId={`stop.${identifier}`} />,
              formatter: ({ doc }: { doc: Stop }) => {
                const reportComments = doc.report?.comments;

                if (reportComments) {
                  return (
                    <Tooltip title={reportComments}>
                      <Text variant="body" style={{ maxWidth: `110px` }}>
                        {reportComments}
                      </Text>
                    </Tooltip>
                  );
                }

                return <span>-</span>;
              },
            });
          }
          break;
        case `report_signature`:
          if (supervisor.isStopCompletedFieldEnabled(`signature`)) {
            columns.push({
              width: 110,
              accessorName: `report_signature`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.report_signature" />,
              formatter: ({ doc }: { doc: Stop }) => {
                const stopReport = doc.report;

                if (!stopReport || stopReport.type === `service_report_canceled`) {
                  return <span>-</span>;
                }

                const reportSignature = doc.report.signature;
                return <Text variant="body" style={{ maxWidth: `110px` }} intlId={reportSignature ? `yes` : `no`} />;
              },
            });
          }
          break;
        case `report_images`:
          if (supervisor.isStopCompletedFieldEnabled(`images`) || supervisor.isStopCanceledFieldEnabled(`images`)) {
            columns.push({
              width: 110,
              accessorName: `report_images`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.report_images" />,
              formatter: ({ doc }: { doc: Stop }) => {
                const stopReport = doc.report;

                if (!stopReport) {
                  return <span>-</span>;
                }

                const reportImages = doc.report.images;

                if (reportImages !== undefined) {
                  return (
                    <Text
                      variant="body"
                      style={{ maxWidth: `110px` }}
                      intlId={reportImages.length > 0 ? `yes` : `no`}
                    />
                  );
                }
                return <span>-</span>;
              },
            });
          }
          break;
        case `report_cancel_reason`:
          if (supervisor.isStopCanceledFieldEnabled(`cancel_reason`)) {
            columns.push({
              width: 160,
              accessorName: identifier,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId={`stop.${identifier}`} />,
              formatter: ({ doc }: { doc: Stop }) => {
                const stopReport = doc.report;

                if (!stopReport || stopReport.type === `service_report_completed`) {
                  return <span>-</span>;
                }

                const reportCancelReason = doc.report.cancel_reason;

                if (reportCancelReason !== undefined) {
                  return (
                    <Text
                      variant="body"
                      style={{ maxWidth: `110px` }}
                      intlId={`stop.report.reason.${reportCancelReason}`}
                    />
                  );
                }
                return undefined;
              },
            });
          }
          break;
        case `location`:
          if (supervisor.isStopFieldEnabled(`location`)) {
            columns.push({
              width: 244,
              accessorName: `location`,
              sortable,
              name: <Text variant="label" intlId="stop.location" />,
              formatter: ({ doc }) =>
                doc.location && (doc.location.label || (doc.location.lat && doc.location.lng)) ? (
                  <Tooltip title={doc.location.label ? doc.location.label : `${doc.location.lat}, ${doc.location.lng}`}>
                    <Text tagName="span" variant="body" style={{ maxWidth: `220px` }}>
                      {doc.location.label ? doc.location.label : `${doc.location.lat}, ${doc.location.lng}`}
                    </Text>
                  </Tooltip>
                ) : (
                  <Tooltip title={<Text intlId="planoverview.services.warning" />}>
                    <Text tagName="span" variant="body" style={{ maxWidth: `220px` }}>
                      {`-`}
                    </Text>
                  </Tooltip>
                ),
            });
          }
          break;
        case `duration`:
          if (supervisor.isStopFieldEnabled(`duration`)) {
            columns.push({
              width: 80,
              accessorName: `duration`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.duration" />,
              formatter: ({ value: duration }) => {
                return <Text variant="body">{`${calculateDuration(duration)} min`}</Text>;
              },
            });
          }
          break;
        case `weight`: {
          if (supervisor.isStopFieldEnabled(`weight`)) {
            columns.push({
              width: 50,
              accessorName: `weight`,
              align: `center` as AlignmentTypes,
              sortable,
              name: (
                <Text variant="label">{`${intl.formatMessage({ id: `stop.weight` })} (${project.units?.weight})`}</Text>
              ),
              formatter: ({ value: weight }) => <Text variant="body">{weight !== undefined ? weight : `-`}</Text>,
            });
          }
          break;
        }
        case `volume`: {
          if (supervisor.isStopFieldEnabled(`volume`)) {
            columns.push({
              width: 50,
              accessorName: `volume`,
              align: `center` as AlignmentTypes,
              sortable,
              name: (
                <Text variant="label">{`${intl.formatMessage({ id: `stop.volume` })} (${project.units?.volume})`}</Text>
              ),
              formatter: ({ value: volume }) => <Text variant="body">{volume !== undefined ? volume : `-`}</Text>,
            });
          }
          break;
        }
        case `max_delivery_time`:
          if (supervisor.isStopFieldEnabled(`max_delivery_time`)) {
            columns.push({
              width: 150,
              accessorName: `max_delivery_time`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.max_delivery_time" />,
              formatter: ({ value: maxDeliveryTime }) =>
                maxDeliveryTime ? <Text variant="body">{`${maxDeliveryTime / 60} min`}</Text> : null,
            });
          }
          break;
        case `reward`:
          if (supervisor.isStopFieldEnabled(`reward`)) {
            columns.push({
              width: 150,
              accessorName: `reward`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.reward" />,
            });
          }
          break;
        case `cluster`:
          if (supervisor.isStopFieldEnabled(`cluster`)) {
            columns.push({
              width: 150,
              accessorName: `cluster`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.cluster" />,
            });
          }
          break;
        case `time_windows`:
          if (supervisor.isStopFieldEnabled(`time_windows`)) {
            columns.push({
              width: 220,
              align: `center` as AlignmentTypes,
              accessorName: `time_windows`,
              name: <Text variant="label" intlId="stop.time_windows" />,
              formatter: ({ doc: stop }) => {
                if (stop.time_windows && stop.time_windows.length > 0) {
                  return stop.time_windows.length > 2 ? (
                    <Grid container alignItems="center" justifyContent="center">
                      <Chip
                        key={stop.time_windows[0][0] + stop.time_windows[0][1]}
                        label={
                          <Text variant="body">{`${parseSecondsToHM(stop.time_windows[0][0])}-${parseSecondsToHM(
                            stop.time_windows[0][1]
                          )}`}</Text>
                        }
                      />
                      <span
                        style={{
                          cursor: `pointer`,
                          color: RoutalPalette.primary40,
                          fontFamily: `Geist Variable`,
                          marginLeft: `4px`,
                          border: `none`,
                        }}
                        onClick={(e) => {
                          if (onOpenRightPanel) {
                            onOpenRightPanel(e, stop);
                          }
                        }}
                      >
                        <Text tagName="span" intlId="view_more" />
                        ...
                      </span>
                    </Grid>
                  ) : (
                    stop.time_windows.map((timeWindow: TimeWindow) => (
                      <Chip
                        key={timeWindow[0] + timeWindow[1]}
                        label={
                          <Text variant="body">{`${parseSecondsToHM(timeWindow[0])}-${parseSecondsToHM(
                            timeWindow[1]
                          )}`}</Text>
                        }
                      />
                    ))
                  );
                }
                return null;
              },
            });
          }
          break;
        case `requires`:
          if (supervisor.isStopFieldEnabled(`requires`)) {
            columns.push({
              width: 220,
              align: `center` as AlignmentTypes,
              accessorName: `requires`,
              name: <Text variant="label" intlId="stop.requires" />,
              formatter: ({ doc: stop }) => {
                if (stop.requires && stop.requires.length > 0) {
                  return stop.requires.length > 2 ? (
                    <Grid container alignItems="center" justifyContent="center">
                      <Tooltip disableHoverListener={stop.requires[0].length <= 8} title={stop.requires[0]}>
                        <Chip
                          key={stop.requires[0]}
                          label={
                            <Text variant="body">{`${stop.requires[0].substring(0, 8)}${
                              stop.requires[0].length > 8 ? `...` : ``
                            }`}</Text>
                          }
                        />
                      </Tooltip>
                      <span
                        style={{
                          cursor: `pointer`,
                          color: RoutalPalette.primary40,
                          fontFamily: `Geist Variable`,
                          marginLeft: `4px`,
                          border: `none`,
                        }}
                        onClick={(e) => {
                          if (onOpenRightPanel) {
                            onOpenRightPanel(e, stop);
                          }
                        }}
                      >
                        <Text tagName="span" intlId="view_more" />
                        ...
                      </span>
                    </Grid>
                  ) : (
                    stop.requires.map((require: string) => (
                      <Tooltip disableHoverListener={require.length <= 8} title={require}>
                        <Chip
                          key={require}
                          label={
                            <Text variant="body">{`${require.substring(0, 8)}${require.length > 8 ? `...` : ``}`}</Text>
                          }
                        />
                      </Tooltip>
                    ))
                  );
                }
                return null;
              },
            });
          }
          break;
        case `additional_info`:
          if (supervisor.isStopFieldEnabled(`additional_info`)) {
            columns.push({
              width: 220,
              accessorName: `additional_info`,
              sortable,
              name: <Text variant="label" intlId="stop.additional_info" />,
              formatter: ({ value: additionalInfo }) => (
                <>
                  {additionalInfo && additionalInfo !== `` ? (
                    <RoutalTooltip title={additionalInfo}>
                      <Text variant="body" style={{ fontWeight: 500 }}>
                        {additionalInfo.length > 30 ? `${additionalInfo.substring(0, 30)}...` : `${additionalInfo}`}
                      </Text>
                    </RoutalTooltip>
                  ) : (
                    <Text
                      variant="body"
                      style={{ fontWeight: 500, color: `${RoutalPalette.secondary20}` }}
                      intlId="no_name"
                    />
                  )}
                </>
              ),
            });
          }
          break;
        case `customer_text_field`:
          if (supervisor.isStopFieldEnabled(`customer_text_field`)) {
            columns.push({
              width: 200,
              accessorName: `customer_text_field`,
              sortable,
              name: <Text variant="label" intlId="stop.customer_text_field" />,
              formatter: ({ value: customerTextField }) => (
                <>
                  {customerTextField && customerTextField !== `` ? (
                    <RoutalTooltip title={customerTextField}>
                      <Text variant="body" style={{ fontWeight: 500 }}>
                        {customerTextField.length > 30
                          ? `${customerTextField.substring(0, 30)}...`
                          : `${customerTextField}`}
                      </Text>
                    </RoutalTooltip>
                  ) : (
                    <Text variant="body">-</Text>
                  )}
                </>
              ),
            });
          }
          break;
        case `estimated_arrival_time`:
        case `estimated_departure_time`:
          if (supervisor.isStopFieldEnabled(identifier)) {
            columns.push({
              width: 130,
              accessorName: identifier,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId={`stop.${identifier}`} />,
              formatter: ({ value: time }) => {
                if (time === undefined) return <span>-</span>;
                return <Text variant="body">{dateFormatShortTime(time)}</Text>;
              },
            });
          }
          break;
        case `planned_arrival_time`:
        case `planned_departure_time`:
          if (supervisor.isStopFieldEnabled(identifier)) {
            columns.push({
              width: 130,
              accessorName: identifier,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId={`stop.${identifier}`} />,
              formatter: ({ value: time }) => {
                if (time === undefined) return <span>-</span>;
                return <Text variant="body">{parseSecondsToHM(time)}</Text>;
              },
            });
          }
          break;
        case `email`:
        case `client_external_id`:
        case `external_id`:
        case `location_details`:
        case `comments`:
        case `reference_person`:
        case `phone`:
        case `url`:
        case `pin`:
          // case `optional`:
          if (supervisor.isStopFieldEnabled(identifier)) {
            columns.push({
              width: [`url`, `phone`].includes(identifier) ? 120 : 220,
              accessorName: identifier,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId={`stop.${identifier}`} />,
              // ...(identifier === `optional`
              //   ? {
              //       formatter: (doc: boolean) => <Text variant="body" style={{ maxWidth: `110px` }} intlId={doc ? `yes` : `no`} />,
              //     }
              //   : {}),
              formatter: ({ value }) =>
                value && (
                  <Tooltip title={value}>
                    <Text variant="body" style={{ maxWidth: `120px` }}>
                      {value}
                    </Text>
                  </Tooltip>
                ),
              ...(identifier === `phone`
                ? {
                    formatter: ({ value: phone }) => {
                      if (!phone) return undefined;

                      const parsedPhone = parsePhoneNumber(phone);
                      if (!parsedPhone) return undefined;

                      return <Text variant="body">{parsedPhone.formatInternational()}</Text>;
                    },
                  }
                : {}),
            });
          }
          break;
        case `execution_date`:
          if (supervisor.isStopFieldEnabled(identifier)) {
            columns.push({
              width: 130,
              accessorName: `execution_date`,
              align: `center` as AlignmentTypes,
              sortable,
              name: <Text variant="label" intlId="stop.execution_date" />,
              formatter: ({ value: executionDate }) => {
                if (!executionDate) return <span>-</span>;
                return (
                  <RoutalTooltip title={dateFormat(executionDate)}>
                    <Text variant="body">{pastDateTime(executionDate)}</Text>
                  </RoutalTooltip>
                );
              },
            });
          }
          break;
        case `created_at`:
          break;
        default: {
          if ((identifier as StopsTableColumnKeys).includes(CUSTOM_FIELD_COLUMN_ID_PREFIX)) {
            let customFieldDefinitionLabel: string | undefined = undefined;
            let formatter:
              | (({ value, doc, index, rowHover }: { value: any; doc: any; index: number; rowHover: boolean }) => any)
              | undefined = undefined;

            if (identifier in stopCustomFieldDefinitionsMap) {
              const customFieldDefinition = stopCustomFieldDefinitionsMap[identifier];
              customFieldDefinitionLabel = customFieldDefinition?.label;
              formatter = ({ doc }: { doc: Stop }) => {
                if (!doc?.custom_fields) {
                  return null;
                }
                const stopCustomFields = doc.custom_fields.value;
                const customField = stopCustomFields[customFieldDefinition.custom_id] as CustomFieldValues;

                return customFieldToTableValue({
                  doc,
                  customField,
                  customFieldDefinition,
                  onOpenRightPanel,
                });
              };

              // Report completed custom fields.
            } else if (
              (identifier as StopsTableColumnKeys).includes(REPORT_COMPLETED_CUSTOM_FIELD_COLUMN_ID_PREFIX) &&
              identifier in reportCompletedCustomFieldDefinitionsMap
            ) {
              const customFieldDefinition = reportCompletedCustomFieldDefinitionsMap[identifier];
              customFieldDefinitionLabel = customFieldDefinition?.label;
              formatter = ({ doc }: { doc: Stop }) => {
                if (doc.status !== `completed` && doc.status !== `incomplete`) {
                  return null;
                }
                const currentReport = doc.report;
                if (!currentReport || !currentReport.custom_fields) {
                  return null;
                }
                const reportCompletedCustomFields = currentReport.custom_fields!.value;
                const customField = reportCompletedCustomFields[customFieldDefinition.custom_id] as CustomFieldValues;

                return customFieldToTableValue({
                  doc,
                  customField,
                  customFieldDefinition,
                  onOpenRightPanel: (event, stop) => {
                    if (onOpenRightPanel) {
                      onOpenRightPanel(event, stop, `report`);
                    }
                  },
                });
              };

              // Report canceled custom fields.
            } else if (
              (identifier as StopsTableColumnKeys).includes(REPORT_CANCELED_CUSTOM_FIELD_COLUMN_ID_PREFIX) &&
              identifier in reportCanceledCustomFieldDefinitionsMap
            ) {
              const customFieldDefinition = reportCanceledCustomFieldDefinitionsMap[identifier];
              customFieldDefinitionLabel = customFieldDefinition?.label;
              formatter = ({ doc }: { doc: Stop }) => {
                if (doc.status !== `canceled`) {
                  return null;
                }

                const currentReport = doc.report;
                if (!currentReport || !currentReport.custom_fields) {
                  return null;
                }
                const reportCanceledCustomFields = currentReport.custom_fields!.value;
                const customField = reportCanceledCustomFields[customFieldDefinition!.custom_id] as CustomFieldValues;

                return customFieldToTableValue({
                  doc,
                  customField,
                  customFieldDefinition: customFieldDefinition!,
                  onOpenRightPanel: (event, stop) => {
                    if (onOpenRightPanel) {
                      onOpenRightPanel(event, stop, `report`);
                    }
                  },
                });
              };
            }

            if (customFieldDefinitionLabel !== undefined) {
              columns.push({
                width: 160,
                accessorName: identifier,
                sortable,
                align: `center` as AlignmentTypes,
                name: <Text variant="label">{customFieldDefinitionLabel}</Text>,
                formatter,
              });
            }
          }
          break;
        }
      }
    }
  });

  const customersLink = tableColumns.find(
    ({ displayable, identifier }) => identifier === `customers_link` && displayable
  );

  return [
    ...columns,
    ...(customersLink
      ? [
          {
            width: 130,
            accessorName: `customers_link`,
            align: `center` as AlignmentTypes,
            sortable: customersLink.sortable,
            name: <Text variant="label" intlId="stop.customers_link" />,
            formatter: ({ doc: stop }: { doc: Stop }) => (
              <Button
                size="small"
                style={{
                  backgroundColor: project.style?.primary_color?.value ?? RoutalPalette.primary20,
                }}
                onClick={() => {
                  window.open(
                    `${configuration.CUSTOMERS_SLUG_ENDPOINT.replace(`{project_slug}`, project.project_slug)}/stop/${
                      stop.id
                    }`,
                    `_blank`
                  );
                }}
              >
                <OpenInNewIcon />
              </Button>
            ),
          } as DataTableColumnProps,
        ]
      : []),
    ...(tableColumns.find(({ displayable, identifier }) => identifier === `created_at` && displayable)
      ? [
          {
            width: 130,
            accessorName: `created_at`,
            align: `center` as AlignmentTypes,
            sortable: true,
            name: <Text variant="label" intlId="stop.created_at" />,
            formatter: ({ value: createdAt }) => (
              <RoutalTooltip title={dateFormat(createdAt)}>
                <Text variant="body">{pastDateTime(createdAt)}</Text>
              </RoutalTooltip>
            ),
          } as DataTableColumnProps,
        ]
      : []),
  ] as Array<DataTableColumnProps>;
};
