import { ValueObject } from '@nx-smartmonkey/shared/domain';
import { booleanPointInPolygon, point, polygon, Position } from '@turf/turf';
import tinycolor from 'tinycolor2';

import { SMPalette } from '../../Colors';
import { getTransportModeSVGIcon } from './TransportModeSVGIcon';

export type MarkerDomainTypes =
  | `default`
  | `pickup`
  | `truck`
  | `car`
  | `pedestrian`
  | `bicycle`
  | `scooter`
  | `start`
  | `routal_star`
  | `end`
  | `avatar`;

export interface MarkerDomainProps {
  id: string;
  label?: string;
  lat: number;
  lng: number;
  isRouteSpecificMarker?: boolean;
  color?: string;
  kind?: MarkerDomainTypes;
  selected?: boolean;
  done?: boolean;
  error?: boolean;
  incomplete?: boolean;
  number?: number;
  hasWarnings?: boolean;
  orientation?: number;
  avatarUrl?: string;
  onClick?: (e: any, id: string, isRouteSpecificMarker: boolean) => void;
  onDoubleClick?: (e: any, id: string, isRouteSpecificMarker: boolean) => void;
}

export class MarkerDomain extends ValueObject<MarkerDomainProps> {
  private MARKER_NUMBER_LIMIT = 999;
  private _googleMarker?: google.maps.marker.AdvancedMarkerElement;
  private clickEventListener?: google.maps.MapsEventListener;
  private dblClickEventListener?: google.maps.MapsEventListener;

  get id(): string {
    return this.props.id;
  }
  get label(): string | undefined {
    return this.props.label;
  }
  get lat(): number {
    return this.props.lat;
  }
  get lng(): number {
    return this.props.lng;
  }
  get isRouteSpecificMarker(): boolean | undefined {
    return this.props.isRouteSpecificMarker;
  }
  get color(): string | undefined {
    return this.props.color;
  }
  get kind(): MarkerDomainTypes | undefined {
    return this.props.kind;
  }
  get selected(): boolean | undefined {
    return this.props.selected;
  }
  get done(): boolean | undefined {
    return this.props.done;
  }
  get error(): boolean | undefined {
    return this.props.error;
  }
  get incomplete(): boolean | undefined {
    return this.props.incomplete;
  }
  get number(): number | undefined {
    return this.props.number;
  }
  get hasWarnings(): boolean | undefined {
    return this.props.hasWarnings;
  }
  get orientation(): number | undefined {
    return this.props.orientation;
  }
  get avatarUrl(): string | undefined {
    return this.props.avatarUrl;
  }
  get onClick(): ((e: any, id: string, isRouteSpecificMarker: boolean) => void) | undefined {
    return this.props.onClick;
  }
  set onClick(onClick: ((e: any, id: string, isRouteSpecificMarker: boolean) => void) | undefined) {
    this.props.onClick = onClick;
  }
  get onDoubleClick(): ((e: any, id: string, isRouteSpecificMarker: boolean) => void) | undefined {
    return this.props.onDoubleClick;
  }
  set onDoubleClick(onDoubleClick: ((e: any, id: string, isRouteSpecificMarker: boolean) => void) | undefined) {
    this.props.onDoubleClick = onDoubleClick;
  }
  set googleMarker(googleMarker: google.maps.marker.AdvancedMarkerElement | undefined) {
    this._googleMarker = googleMarker;
  }
  get googleMarker(): google.maps.marker.AdvancedMarkerElement | undefined {
    return this._googleMarker;
  }

  private createMarkerIcon(): HTMLElement {
    const { hasWarnings, selected, number, orientation, kind, avatarUrl } = this;

    const markerColor = this.getMarkerColor();

    let svgString: string;
    const tColor = tinycolor(markerColor);
    switch (kind) {
      case `pedestrian`:
      case `bicycle`:
      case `scooter`:
      case `car`:
      case `truck`:
      case `avatar`:
        svgString = getTransportModeSVGIcon({
          color: markerColor,
          selected,
          orientation,
          kind,
          avatarUrl,
        });
        break;
      case `start`:
        svgString = [
          `<?xml version="1.0"?>`,
          `<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">`,
          `<circle cx="14" cy="14" r="10" fill="white" stroke="${markerColor}" stroke-width="4" />`,
          `</svg>`,
        ].join(``);
        break;
      case `routal_star`:
        svgString = [
          `<?xml version="1.0"?>`,
          `<svg id="uuid-ce0f4034-cdc0-4082-be73-a59b0a787516" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 40 40">`,
          `<path fill="#416968" class="uuid-7ffc3c34-3bac-43b2-b504-85990001a7bd" d="M20,.21C9.09.21.21,9.09.21,20s8.88,19.79,19.79,19.79,19.79-8.88,19.79-19.79S30.91.21,20,.21Z" />`,
          `<path fill="white" class="uuid-0aca1697-8c78-423c-809d-c4eb58b0f506" d="M10.69,20.51c4.86,0,8.8,3.94,8.8,8.8.28.3.75.3,1.02,0,0-4.86,3.94-8.8,8.8-8.8.3-.28.3-.75,0-1.02-4.86,0-8.8-3.94-8.8-8.8-.28-.3-.75-.3-1.02,0,0,4.86-3.94,8.8-8.8,8.8-.3.28-.3.75,0,1.02Z" />`,
          `</svg>`,
        ].join(``);
        break;
      case `end`:
        svgString = [
          `<?xml version="1.0"?>`,
          `<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">`,
          `<circle cx="13" cy="13" r="13" fill="${markerColor}" />`,
          `</svg>`,
        ].join(``);
        break;
      case `pickup`:
        svgString = [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">`,
          `<rect width="24" height="24" rx="4" stroke="none" fill="white" />`,
          `<rect x="1.5" y="1.5" width="21" height="21" rx="3.5" fill="${
            selected ? markerColor : tColor.lighten(35).toString()
          }" stroke="${markerColor}" stroke-width="2"/>`,
          `<text x="50%" y="16px" text-anchor="middle" font-family="'Geist Variable', sans-serif" fill="${
            selected ? `white` : markerColor
          }" font-size="10px" font-weight="bold">${number && number > 999 ? `+999` : number ?? ``}</text>`,
          `</svg>`,
        ].join(``);
        break;
      case `default`:
      default:
        if (hasWarnings) {
          svgString = [
            `<?xml version="1.0" encoding="UTF-8"?>`,
            `<svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">`,
            `<g>`,
            `<g>`,

            // Circle to reproduce the box-shadow white
            `<circle stroke="white" stroke-width="2" fill="white" cx="20" cy="20" r="11" />`,

            // Circle to reproduce the marker border
            `<circle stroke="${markerColor}" stroke-width="2" fill="${
              selected ? markerColor : tColor.lighten(35).toString()
            }" cx="20" cy="20" r="10" />`,

            // Number is below MARKER_NUMBER_LIMIT.
            `${
              number && number <= this.MARKER_NUMBER_LIMIT
                ? `<text transform="${
                    number < 10 ? `translate(16.5 23.5)` : `translate(14.5 23.5)`
                  }" font-family="'Geist Variable', sans-serif" fill="${
                    selected ? `white` : markerColor
                  }" font-size="10px" font-weight="bold">${number}</text>`
                : ``
            }`,

            // Number is over MARKER_NUMBER_LIMIT.
            `${
              number && number > this.MARKER_NUMBER_LIMIT
                ? `<text transform="translate(12.5 23)" font-family="'Geist Variable', sans-serif" fill="${
                    selected ? `white` : markerColor
                  }" font-size="8px" font-weight="bold">+${this.MARKER_NUMBER_LIMIT}</text>`
                : ``
            }`,

            `</g>`,
            `<g>`,
            `<circle fill="#fff" cx="9.41" cy="12.47" r="5.5" />`,
            `<path fill="#e03030" d="M9.24,6.18c-3.54,0-6.41,2.87-6.41,6.41,0,3.54,2.87,6.41,6.41,6.41,3.54,0,6.41-2.87,6.41-6.41,0-3.54-2.87-6.41-6.41-6.41,0,0,0,0,0,0Zm.53,3.38l-.1,3.55c0,.24-.18,.45-.43,.46,0,0,0,0,0,0h0c-.24,0-.44-.2-.44-.45,0,0,0,0,0,0l-.1-3.55c0-.29,.23-.54,.52-.54,0,0,0,0,.01,0h0c.29,0,.54,.23,.54,.52,0,0,0,.01,0,.02Zm-.53,6.06c-.31-.01-.56-.28-.55-.59,.01-.31,.28-.56,.59-.55,.31,.01,.55,.26,.55,.57,0,.32-.26,.57-.58,.57,0,0,0,0,0,0h0Z" />`,
            `</g>`,
            `</g>`,
            `</svg>`,
          ].join(``);
        } else {
          svgString = [
            `<?xml version="1.0" encoding="UTF-8"?>`,
            `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">`,

            // Circle to reproduce the box-shadow white
            `<circle stroke="white" stroke-width="2" fill="white" cx="12" cy="12" r="11" />`,

            // Circle to reproduce the marker border
            `<circle stroke="${markerColor}" stroke-width="2" fill="${
              selected ? markerColor : tColor.lighten(35).toString()
            }" cx="12" cy="12" r="10" />`,
            `<text x="50%" y="${
              number && number > this.MARKER_NUMBER_LIMIT ? `15px` : `16px`
            }" text-anchor="middle" font-family="'Geist Variable', sans-serif" fill="${
              selected ? `white` : markerColor
            }" font-size="${number && number > this.MARKER_NUMBER_LIMIT ? `8px` : `10px`}" font-weight="bold">${
              number && number > this.MARKER_NUMBER_LIMIT ? `+${this.MARKER_NUMBER_LIMIT}` : number ?? ``
            }</text>`,
            `</svg>`,
          ].join(``);
        }
        break;
    }
    const parser = new DOMParser();
    return parser.parseFromString(svgString, `image/svg+xml`).documentElement;
  }

  private createMarker(): google.maps.marker.AdvancedMarkerElement {
    const { id, label, lat, lng, selected, isRouteSpecificMarker, onClick, onDoubleClick } = this;

    const content = this.createMarkerIcon();

    const gMarker = new google.maps.marker.AdvancedMarkerElement({
      position: { lat, lng },
      zIndex: selected ? 1000 : 0,
      content,
      // collisionBehavior: selected
      //   ? google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
      //   : google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
    });

    // @ts-ignore
    gMarker.metadata = {
      label,
      marker_id: id,
      selected,
    };

    if (onClick || onDoubleClick) {
      this.clickEventListener = google.maps.event.addListener(gMarker, `click`, (evt: any) => {
        console.info(`[CREATE] click dins del marker listener`, evt.domEvent.detail);
        if (evt.domEvent.detail === 1) {
          if (onClick) onClick(evt, id, isRouteSpecificMarker ?? false);
        } else {
          if (onDoubleClick) onDoubleClick(evt, id, isRouteSpecificMarker ?? false);
        }
      });
    }

    return gMarker;
  }

  private constructor(props: MarkerDomainProps) {
    super(props);
    this.googleMarker = this.createMarker();
  }

  updateMarker() {
    if (this.googleMarker) {
      const { lat, lng } = this;
      const icon = this.createMarkerIcon();

      this.googleMarker.position = { lat, lng };
      this.googleMarker.zIndex = this.selected ? 1000 : 0;
      this.googleMarker.content = icon;

      // @ts-ignore
      this.googleMarker.metadata = {
        label: this.label,
        marker_id: this.id,
        selected: this.selected,
      };

      if (this.clickEventListener) {
        google.maps.event.removeListener(this.clickEventListener);
        this.clickEventListener = undefined;
      }
      if (this.dblClickEventListener) {
        google.maps.event.removeListener(this.dblClickEventListener);
        this.dblClickEventListener = undefined;
      }

      if (this.onClick || this.onDoubleClick) {
        this.clickEventListener = google.maps.event.addListener(this.googleMarker, `click`, (evt: any) => {
          console.info(`[UPDATE] click dins del marker listener`, evt.domEvent.detail);
          if (evt.domEvent.detail === 1) {
            if (this.onClick) this.onClick(evt, this.id, this.isRouteSpecificMarker ?? false);
          } else {
            if (this.onDoubleClick) this.onDoubleClick(evt, this.id, this.isRouteSpecificMarker ?? false);
          }
        });
      }
    }
  }

  updateProps(props: MarkerDomainProps) {
    this.props = props;
  }

  static create(props: MarkerDomainProps): MarkerDomain {
    return new MarkerDomain(props);
  }

  getMarkerColor(): string {
    let iconColor;
    if (this.color === undefined) {
      iconColor = SMPalette[`grey7`];
    } else {
      iconColor = this.color;
    }
    if (!this.selected) {
      if (this.error) {
        iconColor = `#E03030`;
      } else if (this.done) {
        iconColor = `#70D292`;
      } else if (this.incomplete) {
        iconColor = `#FFB300`;
      }
    }
    return iconColor;
  }

  static isMarkerInsideCoordinates({
    markerCoords,
    coordinates,
  }: {
    markerCoords: { lat: number; lng: number };
    coordinates: { lat?: number; lng?: number }[];
  }) {
    // IMPORTANT: We use lng/lat instead of lat/lng because of the GeoJSON format. See: https://macwright.com/lonlat/
    const markerPoint = point([markerCoords.lng, markerCoords.lat]);
    const data = [coordinates.map((coordinate) => [coordinate.lng, coordinate.lat] as Position)];
    const poly = polygon(data);
    const selected = booleanPointInPolygon(markerPoint, poly);
    return selected;
  }

  isInsideBounds(bounds: google.maps.LatLngBounds) {
    const markerLatLng = new google.maps.LatLng(this.lat, this.lng);
    return bounds.contains(markerLatLng);
  }

  hasSameProps(otherMarkerProps: MarkerDomainProps) {
    return (
      this.lat === otherMarkerProps.lat &&
      this.lng === otherMarkerProps.lng &&
      this.label === otherMarkerProps.label &&
      // this.isRouteSpecificMarker === otherMarkerProps.isRouteSpecificMarker &&
      this.color === otherMarkerProps.color &&
      this.kind === otherMarkerProps.kind &&
      this.selected === otherMarkerProps.selected &&
      this.done === otherMarkerProps.done &&
      this.error === otherMarkerProps.error &&
      this.incomplete === otherMarkerProps.incomplete &&
      this.number === otherMarkerProps.number &&
      this.hasWarnings === otherMarkerProps.hasWarnings &&
      this.orientation === otherMarkerProps.orientation &&
      this.avatarUrl === otherMarkerProps.avatarUrl
    );
  }
}
