import { AddAPhoto, Clear } from '@mui/icons-material';
import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RoutalPalette } from './Colors';
import { ROUTAL_IMAGE_UPLOAD_ID, RoutalImageUpload } from './RoutalImageUpload';

interface ImageContainerProps {
  width: number;
  height: number;
  hasImage?: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`
  border: 1px solid ${RoutalPalette.primary40};
  ${(props: ImageContainerProps) => {
    return `
      width: ${props.width}px;
      height: ${props.height}px;
      background-size: contain;      
      ${
        props.hasImage
          ? `
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      `
          : ``
      }
    `;
  }}
`;

const AddImageContainer = styled(ImageContainer)`
  cursor: pointer;
  position: relative;
  color: ${RoutalPalette.primary40};
  background-color: ${RoutalPalette.neutral05};
  &:hover {
    background-color: ${RoutalPalette.neutral10};
  }
`;

type RoutalAvatarManagerProps = {
  id?: string;
  crop?: boolean;
  shape?: `rect` | `round`;
  width?: number;
  height?: number;
  avatarUrl?: string | null;
  avatarBase64?: string;
  fileName: string;
  maxFileSize: number;
  confirmText?: React.ReactElement | string;
  cancelText?: React.ReactElement | string;
  onRemoveAvatar: (...args: any[]) => any;
  onAvatarUpload: (...args: any[]) => any;
  getUploadAsBase64?: (url: string) => Promise<string | null>;
};

export const RoutalAvatarManager = ({
  id = ROUTAL_IMAGE_UPLOAD_ID,
  crop = true,
  shape = `rect`,
  width = 120,
  height = 120,
  avatarUrl,
  avatarBase64,
  fileName,
  maxFileSize,
  confirmText,
  cancelText,
  onRemoveAvatar,
  onAvatarUpload,
  getUploadAsBase64,
}: RoutalAvatarManagerProps) => {
  const [avatarImage, setAvatarImage] = useState<string | null>(avatarBase64 ?? ``);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);
  const [uploadTimeout, setUploadTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [removeTimeout, setRemoveTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      if (uploadTimeout) {
        clearTimeout(uploadTimeout);
        setUploadTimeout(undefined);
      }
      if (removeTimeout) {
        clearTimeout(removeTimeout);
        setRemoveTimeout(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (avatarUrl && getUploadAsBase64) {
        setIsLoadingImage(true);
        const retrievedAvatar = await getUploadAsBase64(avatarUrl);
        setAvatarImage(retrievedAvatar);
      }
      setIsLoadingImage(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarUrl]);

  useEffect(() => {
    if (avatarBase64) {
      setAvatarImage(avatarBase64);
      setIsLoadingImage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarBase64]);

  const handleRemovePhoto = async () => {
    setIsLoadingImage(true);
    onRemoveAvatar();

    // This is a hack for removing setting the isLoading to false only if remove query has failed.
    setRemoveTimeout(
      setTimeout(() => {
        setIsLoadingImage(false);
        setRemoveTimeout(undefined);
      }, 1000)
    );
  };

  const uploadAvatarImage = async (imageName: string, imageType: string, image: Blob) => {
    setIsLoadingImage(true);
    onAvatarUpload(imageName, imageType, image);

    // This is a hack for removing setting the isLoading to false only if upload query has failed.
    setUploadTimeout(
      setTimeout(() => {
        setIsLoadingImage(false);
        setUploadTimeout(undefined);
      }, 1000)
    );
  };

  return (
    <Grid container direction="column" style={{ width: `${width}px`, height: `${height}px` }}>
      {isLoadingImage ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            height: `${height}px`,
            width: `${width}px`,
            maxHeight: `${height}px`,
            maxWidth: `${width}px`,
            borderRadius: shape === `rect` ? `4px` : `50%`,
            border: `1px solid #707070`,
          }}
        >
          <CircularProgress style={{ color: RoutalPalette.primary40 }} size={32} />
        </Grid>
      ) : (
        <>
          {!avatarUrl && !avatarBase64 ? (
            <div style={{ cursor: `pointer` }}>
              <AddImageContainer
                width={width}
                height={height}
                style={{ borderRadius: shape === `rect` ? `4px` : `50%` }}
                onClick={() => {
                  document.getElementById(id)?.click();
                }}
              >
                <AddAPhoto
                  style={{
                    position: `absolute`,
                    top: `calc(50% - 11px)`,
                    left: `calc(50% - 13px)`,
                  }}
                />
              </AddImageContainer>

              <RoutalImageUpload
                id={id}
                crop={crop}
                aspect={shape === `rect` ? width / height : 1}
                cropShape={shape}
                fileName={fileName}
                maxFileSize={maxFileSize}
                onImageUploaded={uploadAvatarImage}
                confirmText={confirmText}
                cancelText={cancelText}
              />
            </div>
          ) : (
            <div style={{ position: `relative`, width: `${width}px`, height: `${height}px` }}>
              <ImageContainer
                width={width}
                height={height}
                hasImage={Boolean(avatarImage)}
                style={{
                  borderRadius: shape === `rect` ? `4px` : `50%`,
                  backgroundImage: avatarImage ? `url(${avatarImage})` : ``,
                }}
              />
              <div
                style={{
                  position: `absolute`,
                  right: shape === `rect` ? `-8px` : `0px`,
                  top: shape === `rect` ? `-10px` : `2px`,
                  height: `26px`,
                  width: `26px`,
                  cursor: `pointer`,
                  borderRadius: `50%`,
                  color: `white`,
                  backgroundColor: RoutalPalette.error.medium,
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  border: `2px solid ${RoutalPalette.white}`,
                }}
              >
                <Clear style={{ fontSize: `24px` }} onClick={handleRemovePhoto} />
              </div>
            </div>
          )}
        </>
      )}
    </Grid>
  );
};
