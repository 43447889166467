import { LanguageType } from '@nx-smartmonkey/shared/domain';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import { setSelectedIntl } from '../../helpers/IntGlobalProvider';
import { TranslationsActions, TranslationsActionType } from './actions';

export interface TranslationsState {
  translations: Record<string, IntlShape>;
  activeLanguage?: IntlShape;
}

export const getInitialState = (): TranslationsState => ({
  translations: {},
  activeLanguage: undefined,
});

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const TranslationsReducer = (state: TranslationsState, action: TranslationsActions) => {
  switch (action.type) {
    case TranslationsActionType.SET_ACTIVE_LANGUAGE:
      setSelectedIntl(state.translations[action.payload.language.value]);
      return {
        ...state,
        activeLanguage: state.translations[action.payload.language.value],
      };
    case TranslationsActionType.SET_TRANSLATIONS: {
      const translations = action.payload.translations.reduce(
        (
          amount: Record<string, IntlShape>,
          { language, translations }: { language: LanguageType; translations: Record<string, string> }
        ) => {
          return {
            ...amount,
            [language]: createIntl(
              {
                locale: language,
                // @ts-ignore FIXME!!
                messages: translations,
              },
              cache
            ),
          };
        },
        {}
      );
      return {
        ...state,
        translations: {
          ...state.translations,
          ...translations,
        },
      };
    }
    default:
      return state;
  }
};
