import 'non.geist';

import { adaptV4Theme, createTheme } from '@mui/material/styles';

import { RoutalPalette } from './Colors';

// Check all possible fields at: https://material-ui.com/customization/default-theme/
export default createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: [`Geist Variable`].join(`,`),
      h1: {
        color: RoutalPalette.text,
        fontSize: `52px`,
        lineHeight: `60px`,
        fontWeight: 500,
      },
      h2: {
        color: RoutalPalette.text,
        fontSize: `28px`,
        lineHeight: `30px`,
        fontWeight: 500,
      },
      h3: {
        color: RoutalPalette.text,
        fontSize: `24px`,
        lineHeight: `26px`,
        fontWeight: 500,
      },
      h4: {
        color: RoutalPalette.text,
        fontSize: `22px`,
        lineHeight: `24px`,
        fontWeight: 500,
      },
      h5: {
        color: RoutalPalette.text,
        fontSize: `20px`,
        fontWeight: 500,
        lineHeight: `22px`,
      },
      h6: {
        color: RoutalPalette.text,
        fontSize: `18px`,
        fontWeight: 500,
        lineHeight: `20px`,
      },
      body1: {
        fontSize: `14px`,
        lineHeight: `16px`,
        color: RoutalPalette.text,
      },
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ fontFamily: string; h1: { color: string; f... Remove this comment to see the full error message
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: `#406867`,
      },
      secondary: {
        main: `#57637C`,
      },
      error: {
        main: RoutalPalette.error.medium,
      },
    },
    button: {
      fontSize: `12px`,
      error: {
        main: RoutalPalette.error.medium,
      },
    },
    overrides: {
      MuiStepIcon: {
        text: {
          fill: `white`,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: RoutalPalette.grey6,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: RoutalPalette.neutral00,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: RoutalPalette.neutral10,
        },
      },
      MuiRadio: {
        root: {},
      },
      MuiAutocomplete: {
        root: {
          '&:hover': {},
        },
      },
      MuiOutlinedInput: {
        root: {
          border: `1px solid ${RoutalPalette.neutral20}`,
          boxShadow: `none`,
          color: RoutalPalette.text,
          borderRadius: `5px`,

          '&:hover': {
            border: `1px solid ${RoutalPalette.neutral20}`,
            backgroundColor: RoutalPalette.neutral05,
            outline: `none`,
          },
          '&:focus': {
            borderColor: RoutalPalette.primary00,
            border: `1px solid ${RoutalPalette.primary00}`,
            outline: `none`,
          },
          '&:focus-visible': {
            outline: `none`,
          },
          '&:active': {
            borderColor: RoutalPalette.primary00,
            border: `1px solid ${RoutalPalette.primary00}`,
          },
          outline: `none`,
          borderSize: `1px`,
        },
        input: {
          fontSize: `14px`,
          lineHeight: `16px`,
          color: RoutalPalette.text,
          outline: `none`,
          borderSize: `1px`,
          fontFamily: [`Geist Variable`].join(`,`),
          borderRadius: `5px`,
          border: `1px`,

          '&:hover': {
            border: `none`,
            backgroundColor: RoutalPalette.neutral05,
            outline: `none`,
          },
          '&:focus': {
            border: `none`,
            outline: `none`,
          },
          '&:focus-visible': {
            outline: `none`,
          },
          '&:active': {
            border: `none`,
          },
        },
        notchedOutline: {
          fontSize: `14px`,
          lineHeight: `16px`,
          color: RoutalPalette.text,
          borderRadius: `5px`,
          border: `none`,
          fontFamily: [`Geist Variable`].join(`,`),

          '&:hover': {
            border: `none`,
            outline: `none`,
          },
          '&:focus': {
            border: `none`,
            outline: `none`,
          },
          '&:focus-visible': {
            border: `none`,
            outline: `none`,
          },
          '&:active': {
            border: `none`,
            outline: `none`,
          },
        },
      },

      MuiInputBase: {
        root: {
          '&.MuiOutlinedInput-input': {
            outline: `none`,
          },
          '&.FormArray-noPadding-507': {
            outline: `none`,
          },
          '&.css-24rejj-MuiInputBase-input-MuiOutlinedInput-input': {
            outline: `none`,
          },
          outline: `none`,
          '&:hover': {
            border: `none`,
            outline: `none`,
          },
          '&:focus': {
            border: `none`,
            outline: `none`,
          },
          '&:focus-visible': {
            border: `none`,
            outline: `none`,
          },
          '&:active': {
            border: `none`,
            outline: `none`,
          },
        },
      },
      MuiMenuItem: {
        root: {
          backgroundColor: `white`,
          '&:hover': {
            backgroundColor: RoutalPalette.neutral05,
          },
        },
      },
      MuiStepLabel: {
        label: {
          '&.Mui-completed': {
            color: RoutalPalette.secondary00,
          },
          '&.css-xr2h9o-MuiStepLabel-label': {
            color: RoutalPalette.secondary00,
          },
        },
      },
    },
  })
);
