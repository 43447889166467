import { Tab, Tabs, styled, useMediaQuery } from '@mui/material';
import { cloneElement, useEffect, useState } from 'react';
import { RoutalPalette } from './Colors';

interface RoutalTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const RoutalTabs = styled((props: RoutalTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  width: `100%`,
  height: `100%`,
  '& .MuiTabs-indicator': {
    display: `flex`,
    justifyContent: `center`,
    backgroundColor: `transparent`,
  },
  '& .MuiTabs-indicatorSpan': {
    width: `100%`,
    backgroundColor: RoutalPalette.primary40,
  },
  '& .MuiTabs-flexContainer': {
    height: `100%`,
  },
  '& button': {
    minHeight: `55px`,
  },
});

interface RoutalTabProps {
  label?: string;
  icon: React.ReactElement;
  iconPosition?: `top` | `bottom` | `start` | `end`;
  value: string;
  style?: React.CSSProperties;
  setHover?: (hover: boolean) => void;
}

export const RoutalTab = (props: RoutalTabProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const isMediumDesktop = useMediaQuery(`(max-width: 1440px)`);
  const { label, icon, setHover, ...other } = props;
  const newIcon = cloneElement(icon, { isHover });

  useEffect(() => {
    if (setHover) setHover(isHover);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover]);

  return (
    <StyledTabContent
      {...other}
      icon={newIcon}
      {...(!isMediumDesktop ? { label } : {})}
      // @ts-ignore
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
};

const StyledTabContent = styled((props: RoutalTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  display: `flex`,
  flex: 1,
  minWidth: `24px`,
  textTransform: `none`,
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),
  color: RoutalPalette.neutral60,
  '&.Mui-selected': {
    color: RoutalPalette.primary40,
    '&. MuiSvgIcon-root': {
      color: RoutalPalette.primary40,
    },
  },
  '&:hover': {
    color: RoutalPalette.primary20,
  },
  '&.Mui-focusVisible': {
    backgroundColor: `rgba(100, 95, 228, 0.32)`,
  },
}));
