import { Fade, Grid } from '@mui/material';
import { RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import styled from 'styled-components';

import { pastDateTime } from '@nx-smartmonkey/shared/helpers';
import { useEffect } from 'react';
import CrossIcon from '../assets/svg/CrossIcon';
import { NotificationProps } from '../context/notifications/reducer';
import { useNotifications } from '../hooks/notifications/useNotifications';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useRetrieveRightPanelInfo } from '../hooks/right-panel/useRetrieveRightPanelInfo';
import { Text } from '../react-components/Text';
import { getStopColorByStatus } from './StopStatusBar';

const StyledCloseIcon = styled(CrossIcon)`
  width: 15px !important;
  height: 15px !important;
  font-size: 15px !important;
  color: ${RoutalPalette.neutral40};
  padding: 4px;
  cursor: pointer;
`;

const CloseIcon = ({ onClose }: { onClose?: (...args: any[]) => any }) => {
  return <StyledCloseIcon onClick={onClose} />;
};

interface NotificationRightPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NotificationRightPanel = ({ isOpen, onClose }: NotificationRightPanelProps) => {
  const { notifications, newNotifications, removeNotifications, clearNewNotifications } = useNotifications();
  const { data: project } = useRetrieveProject();
  const { setRightPanelInfo } = useRetrieveRightPanelInfo();

  useEffect(() => {
    if (newNotifications.length > 0) {
      clearNewNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotifications]);

  if (!project) return null;

  const parsedNotifications = notifications.sort((o1, o2) => (o1.date < o2.date ? 1 : -1));

  const getNotificationText = (notification: NotificationProps) => {
    const isClickable = isNotificationClickable(notification);
    switch (notification.event_id) {
      case `smartmonkey.planner.2.planner.stop.deleted`:
        return (
          <Text
            style={{ cursor: isClickable ? `pointer` : `default` }}
            variant="label"
            intlId="supervisors.notifications.stop.deleted"
          />
        );
      case `smartmonkey.planner.2.planner.stop.created`:
        return (
          <Text
            style={{ cursor: isClickable ? `pointer` : `default` }}
            variant="label"
            intlId="supervisors.notifications.stop.created"
          />
        );
      case `smartmonkey.planner.2.planner.stop.rescheduled`:
        return (
          <Text
            style={{ cursor: isClickable ? `pointer` : `default` }}
            variant="label"
            intlId="supervisors.notifications.stop.rescheduled"
          />
        );
      case `smartmonkey.planner.2.planner.stop.updated`:
        return (
          <Text
            style={{ cursor: isClickable ? `pointer` : `default` }}
            variant="label"
            intlId="supervisors.notifications.stop.updated"
          />
        );
      case `smartmonkey.planner.2.planner.stop.reported`:
        return (
          <Text
            variant="label"
            intlId={`supervisors.notifications.stop.reported.${notification.data.status}`}
            intlValues={{
              color: (chunks: any) => (
                <span style={{ color: getStopColorByStatus(notification.data.status) }}>{chunks}</span>
              ),
            }}
            style={{ cursor: isClickable ? `pointer` : `default` }}
          />
        );
      default:
        return null;
    }
  };

  const isNotificationClickable = (notification: NotificationProps) => {
    switch (notification.event_id) {
      case `smartmonkey.planner.2.planner.stop.created`:
      case `smartmonkey.planner.2.planner.stop.rescheduled`:
      case `smartmonkey.planner.2.planner.stop.updated`:
      case `smartmonkey.planner.2.planner.stop.reported`:
        return true;
      case `smartmonkey.planner.2.planner.stop.deleted`:
      default:
        return false;
    }
  };

  return (
    <Grid
      key="notifications-right-panel"
      container
      style={{
        width: `unset`,
        backgroundColor: RoutalPalette.neutral00,
      }}
      sx={{
        position: { xs: `fixed`, md: `relative` },
        top: { xs: `70px`, md: `unset` },
        left: { xs: 0, md: `unset` },
        height: { xs: `calc(100vh - 70px)`, md: `unset` },
        zIndex: { xs: 450, md: `unset` },
      }}
    >
      <div
        style={{
          height: `100%`,
          zIndex: 450,
          backgroundColor: RoutalPalette.neutral00,
          borderLeft: `1px solid ${RoutalPalette.neutral20}`,
          position: `relative`,
        }}
      >
        <Fade in={isOpen} mountOnEnter>
          <Grid style={{ height: `100%` }}>
            <Grid
              container
              direction="column"
              style={{
                padding: `0`,
                height: `100vh`,
              }}
              sx={{
                width: { xs: `100vw`, md: `400px` },
              }}
              wrap="nowrap"
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  padding: `0 22px`,
                  minHeight: `70px`,
                  borderBottom: `1px solid ${RoutalPalette.neutral20}`,
                }}
                wrap="nowrap"
              >
                <Text variant="body-large" isBold intlId="supervisors.notifications" />

                <CloseIcon onClose={() => onClose()} />
              </Grid>

              <VSpacer small />

              <Grid item container style={{ height: `calc(100% - 78px)`, overflowY: `auto` }}>
                <Grid
                  style={{
                    width: `100%`,
                    display: `flex`,
                    justifyContent: `flex-end`,
                    flexDirection: `row`,
                    margin: `0px 10px`,
                  }}
                >
                  <Text
                    variant="button"
                    intlId="supervisors.notifications.clear"
                    onClick={() => removeNotifications()}
                    style={{
                      padding: `0`,
                      margin: `0`,
                      fontWeight: `normal`,
                      fontSize: `13px`,
                    }}
                  />
                </Grid>
                <div style={{ width: `100%`, height: `100%` }}>
                  <Grid container direction="column" wrap="nowrap" style={{ height: `100%` }}>
                    {parsedNotifications.length === 0 ? (
                      <Grid
                        item
                        container
                        wrap="nowrap"
                        style={{ padding: `8px 16px`, paddingTop: `22px` }}
                        justifyContent="center"
                      >
                        <Text variant="label" intlId="supervisors.notifications.empty" />
                      </Grid>
                    ) : (
                      <>
                        {parsedNotifications.map((notification, index) => (
                          <Grid
                            key={`notification-${index}`}
                            item
                            container
                            wrap="nowrap"
                            style={{
                              padding: `8px 16px`,
                              cursor: isNotificationClickable(notification) ? `pointer` : `default`,
                            }}
                            onClick={() => {
                              if (isNotificationClickable(notification)) {
                                setRightPanelInfo({
                                  detailId: notification.data.id,
                                });

                                onClose();
                              }
                            }}
                          >
                            <Grid item style={{ minWidth: `50px` }}>
                              <Text
                                variant="label"
                                style={{
                                  textAlign: `center`,
                                  cursor: isNotificationClickable(notification) ? `pointer` : `default`,
                                }}
                              >
                                {pastDateTime(notification.date)}
                              </Text>
                            </Grid>

                            <Grid container direction="column" wrap="nowrap">
                              <Grid item container direction="row">
                                <Grid item>
                                  <Text
                                    style={{ cursor: isNotificationClickable(notification) ? `pointer` : `default` }}
                                  >
                                    {notification.data.label}
                                  </Text>
                                </Grid>
                              </Grid>

                              <Grid item>{getNotificationText(notification)}</Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </div>
    </Grid>
  );
};
