import { Grid } from '@mui/material';
import {
  dateFormatShortTime,
  getDiffTimeText,
  parseHMtoSeconds,
  parseSecondsToHM,
} from '@nx-smartmonkey/shared/helpers';
import { HSpacer, RoutalPalette } from '@nx-smartmonkey/ui';

import { StopCircleColor } from '../../../components/StopCircleColor';
import { StopStatusBar } from '../../../components/StopStatusBar';
import { Stop as StopDomain } from '../../../domain/stop/Stop';
import { Text } from '../../../react-components/Text';
import { RightPanelHeader } from './RightPanel.styled';

const StopTimeDiff = ({ plannedTimeSeconds }: { plannedTimeSeconds: number }) => {
  const realDateHM = dateFormatShortTime(new Date());
  const realDateSeconds = parseHMtoSeconds(realDateHM);
  if (realDateSeconds > plannedTimeSeconds) {
    const diff = getDiffTimeText(realDateSeconds - plannedTimeSeconds);
    return (
      <Grid container direction="row" alignItems="center">
        <span style={{ color: `${RoutalPalette.grey7}`, fontSize: `10px` }}>{`+${diff}`}</span>
        <Text
          tagName="span"
          intlId="late"
          style={{
            marginLeft: `4px`,
            color: `${RoutalPalette.grey7}`,
            fontSize: `10px`,
            textTransform: `lowercase`,
          }}
        />
      </Grid>
    );
  }
  return null;
};

type StopHeaderProps = {
  stop: StopDomain;
  onClose: (...args: any[]) => any;
  isSelected?: boolean;
};

export const StopHeader = ({ stop, isSelected, onClose }: StopHeaderProps) => {
  if (stop) {
    return (
      <RightPanelHeader onClose={onClose}>
        <Grid container direction="row" wrap="nowrap">
          <Grid item style={{ height: `38px` }}>
            <StopStatusBar status={stop.status} />
          </Grid>

          <HSpacer small />

          <Grid item container justifyContent="flex-start" style={{ width: `100%` }} wrap="nowrap" alignItems="center">
            <Grid item>
              <StopCircleColor stop={stop} isSelected={isSelected} />
            </Grid>

            <HSpacer small />

            {(stop.planned_arrival_time || stop.planned_arrival_time === 0) && stop.status === `in_transit` ? (
              <Grid container direction="column">
                <span style={{ color: `${RoutalPalette.neutral40}`, fontSize: `13px` }}>
                  {parseSecondsToHM(stop.planned_arrival_time)}
                </span>
                <StopTimeDiff plannedTimeSeconds={stop.planned_arrival_time} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </RightPanelHeader>
    );
  }
  return null;
};
