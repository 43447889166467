import ArrowDropDownIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette, RoutalSelect, RoutalSelectMultiSelectOption } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { Language, validLanguages } from '@nx-smartmonkey/shared/domain';
import { sessionService } from '../application/SessionService';
import LogoRoutal from '../assets/img/horizontal_logo_white.svg';
import { Logout } from '../assets/svg/Logout';
import { Notifications } from '../assets/svg/Notifications';
import { LoaderPage } from '../components/LoaderPage';
import { NotificationRightPanel } from '../components/NotificationRightPanel';
import * as routing from '../constants/Routing';
import { ProjectStyle } from '../domain/projectStyle/ProjectStyle';
import { useNotifications } from '../hooks/notifications/useNotifications';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useSession } from '../hooks/session/useSession';
import { useSessionLogout } from '../hooks/session/useSessionLogout';
import { useRetrieveSupervisor } from '../hooks/supervisor/useRetrieveSupervisor';
import { useSupervisorWebSockets } from '../hooks/supervisor/useSupervisorWebSockets';
import { useUpdateSupervisor } from '../hooks/supervisor/useUpdateSupervisor';
import { useSetActiveLanguage } from '../hooks/translations/useSetActiveLanguage';
import { useBrowserNotifications } from '../hooks/useBrowserNotifications';
import { useIsExampleURL } from '../hooks/useIsExampleURL';
import { Button } from '../react-components/Button';
import { Text } from '../react-components/Text';
import { eventsService } from '../services';
import { Stops } from './stops';

export const HEADER_HEIGHT = 70;

type HeaderBackgroundProps = {
  projectStyle?: ProjectStyle;
};

const HeaderBackground = styled.div<HeaderBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  z-index: -1;
  ${(props: HeaderBackgroundProps) => {
    if (props.projectStyle?.banner_type === `image`) {
      if (!props.projectStyle?.banner_image) {
        return `
          :before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: ${props.projectStyle?.banner_color?.value || RoutalPalette.primary60};
            z-index: -1;
          }
        `;
      } else {
        return `
          background-image: url(${props.projectStyle?.banner_image});
          background-size: cover;
        `;
      }
    }

    return `
      background-color: ${props.projectStyle?.banner_color?.value || RoutalPalette.secondary00};
      
    `;
  }}
`;

type HeaderContainerProps = {
  asSupervisor?: string;
};

const HeaderContainer = styled.header<HeaderContainerProps>`
  position: sticky;
  height: ${HEADER_HEIGHT}px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  border: ${({ asSupervisor }: HeaderContainerProps) => (asSupervisor ? `1px solid tomato;` : ``)};
`;

let mixpanelPageViewSet = false;

export const PrivateComponent = () => {
  const { isLogged } = useSession();
  const { isExampleURL } = useIsExampleURL();
  const { run: logout } = useSessionLogout();
  const { data: supervisor } = useRetrieveSupervisor();
  const { run: updateSupervisor } = useUpdateSupervisor();
  const { data: project, isLoading, error } = useRetrieveProject();
  const { data: activeLocale, run: setActiveLocale } = useSetActiveLanguage();
  const { newNotifications } = useNotifications();
  useBrowserNotifications();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  useSupervisorWebSockets();

  useEffect(() => {
    if (!!project && !mixpanelPageViewSet) {
      eventsService.register({ Platform: `web` });
      eventsService.track({
        eventName: `Page View`,
        params: {
          [`Page Name`]: `Supervisors`,
          [`Organization ID`]: project.organization_id,
        },
      });
      mixpanelPageViewSet = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (supervisor?.language && activeLocale?.locale !== supervisor.language.value) {
      setActiveLocale({ language: supervisor.language });
    }
  }, [activeLocale?.locale, setActiveLocale, supervisor?.language]);

  if (!isExampleURL && (!isLogged || error)) {
    return <Navigate to={routing.AUTH} />;
  }

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid container direction="column">
        {supervisor ? (
          <HeaderContainer asSupervisor={sessionService.getAsSupervisor()}>
            <HeaderBackground projectStyle={project?.style} />
            <Grid container style={{ width: `100%` }}>
              {project?.style?.logo ? (
                <img height="70px" src={project?.style?.logo} alt="" draggable="false" />
              ) : (
                <img height="15px" src={LogoRoutal} alt="" draggable="false" />
              )}
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{
                width: `unset`,
                height: `100%`,
              }}
              wrap="nowrap"
            >
              <Grid item container alignItems="center" style={{ height: `100%` }}>
                <RoutalSelect
                  id="select-language"
                  style={{
                    margin: 0,
                    fontWeight: `normal`,
                    border: `none`,
                    backgroundColor: `transparent`,
                  }}
                  hideArrow
                  popoverProps={{
                    options: validLanguages.map(
                      (locale): RoutalSelectMultiSelectOption => ({
                        key: locale,
                        content: <Text style={{ cursor: `pointer` }} intlId={`language.${locale}`} />,
                        disabled: activeLocale?.locale === locale,
                        onClick: () => {
                          if (isExampleURL) {
                            setActiveLocale({ language: Language.create(locale) });
                          } else {
                            updateSupervisor({ supervisor, changes: { language: Language.create(locale) } });
                          }
                        },
                      })
                    ),
                  }}
                >
                  <Grid item container direction="row" wrap="nowrap" justifyContent="flex-start" alignItems="center">
                    <Grid item sx={{ display: { xs: `none`, md: `block` } }}>
                      <Text
                        variant="body"
                        style={{ marginTop: `2px`, cursor: `pointer`, color: RoutalPalette.neutral00 }}
                        intlId={`language.${activeLocale?.locale}.short`}
                      />
                    </Grid>

                    <Grid item sx={{ display: { xs: `none`, md: `block` } }}>
                      <HSpacer small />
                    </Grid>

                    <Grid item sx={{ display: { xs: `none`, md: `block` } }} style={{ marginTop: `2px` }}>
                      <ArrowDropDownIcon style={{ color: RoutalPalette.neutral00 }} />
                    </Grid>
                  </Grid>
                </RoutalSelect>
              </Grid>

              <Grid item>
                <HSpacer small />
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                direction="row"
                wrap="nowrap"
                onClick={() => {
                  if (!isExampleURL) {
                    logout();
                  }
                }}
                style={{
                  marginTop: `6px`,
                }}
              >
                <Grid item>
                  <Logout
                    color={RoutalPalette.neutral00}
                    style={{ cursor: `pointer`, lineHeight: 0, height: `20px`, width: `20px` }}
                  />
                </Grid>

                <Grid item sx={{ display: { xs: `none`, md: `block` } }}>
                  <HSpacer small />
                </Grid>

                <Grid
                  item
                  sx={{ display: { xs: `none`, md: `block` } }}
                  style={{ marginTop: `-5px`, minWidth: `81px` }}
                >
                  <Text intlId="auth.logout" style={{ cursor: `pointer`, color: RoutalPalette.neutral00 }} />
                </Grid>
              </Grid>

              <Grid item>
                <HSpacer small />
              </Grid>

              {!showNotifications ? (
                <Grid
                  item
                  style={{
                    height: `100%`,
                    width: `100%`,
                    borderLeft: `1px solid ${RoutalPalette.neutral00}`,
                    backgroundColor: project?.style?.secondary_color?.value ?? RoutalPalette.primary60,
                    display: `flex`,
                    alignItems: `center`,
                  }}
                >
                  <Button
                    style={{
                      height: `calc(100% - 8px)`,
                      padding: `0px 20px`,
                      margin: 0,
                      backgroundColor: `transparent`,
                    }}
                    onClick={() => {
                      if (!isExampleURL) {
                        setShowNotifications(true);
                      }
                    }}
                  >
                    <Notifications
                      color={RoutalPalette.neutral00}
                      colorNotification={RoutalPalette.error.medium}
                      hasNewNotifications={newNotifications.length > 0}
                    />
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </HeaderContainer>
        ) : null}

        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{
            position: `absolute`,
            top: `${HEADER_HEIGHT}px`,
            height: `calc(100% - ${HEADER_HEIGHT}px)`,
            overflow: `auto`,
          }}
        >
          {isLoading || !project ? <LoaderPage /> : <Stops />}
        </Grid>
      </Grid>

      {showNotifications && (
        <NotificationRightPanel isOpen={showNotifications} onClose={() => setShowNotifications(false)} />
      )}
    </Grid>
  );
};
