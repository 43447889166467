import { RoutalPalette } from '@nx-smartmonkey/ui';
import tinycolor from 'tinycolor2';

import { Stop } from '../domain/stop/Stop';
import { STATUS_COLOR_LIGHTEN_AMOUNT, getStopColorByStatus } from './StopStatusBar';

type StopCircleColorProps = {
  stop: Stop;
  isSelected?: boolean;
  size?: number;
};

export const StopCircleColor = ({ stop, isSelected, size = 24 }: StopCircleColorProps) => {
  const routeColor = stop.driver_info?.color?.value || RoutalPalette.grey2;
  const tColor = tinycolor(routeColor);

  const tBackgroundColor = stop.status ? tinycolor(getStopColorByStatus(stop.status)) : tColor;

  const lightenBackgroundColor = tBackgroundColor.lighten(STATUS_COLOR_LIGHTEN_AMOUNT).toString();

  const getFillColor = () => (isSelected ? routeColor : lightenBackgroundColor);

  return (
    <div
      style={{
        position: `relative`,
        width: `${size}px`,
        height: `${size}px`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          padding: `0px`,
          width: `100%`,
          height: `100%`,
          position: `relative`,
        }}
      >
        {stop.isPickup() ? (
          <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
            <rect
              x="1.5"
              y="1.5"
              width="21"
              height="21"
              rx="3.5"
              fill={getFillColor()}
              stroke={routeColor}
              strokeWidth={size > 24 ? `1` : `2`}
            />
          </svg>
        ) : (
          <svg viewBox="0 0 48 48" style={{ position: `relative` }}>
            <circle
              cx="24"
              cy="24"
              r="21"
              fill={getFillColor()}
              stroke={routeColor}
              strokeWidth={size > 24 ? `2` : `4`}
            />
          </svg>
        )}
      </div>
    </div>
  );
};
