import { ValueObject } from '../ValueObject';
import { CustomFieldDefinition } from './CustomFieldDefinition';

export type CustomFieldCategoricalSingle = {
  id: number;
  label: string;
};

export type CustomFieldCategoricalMultiple = Array<CustomFieldCategoricalSingle>;

export type CustomFieldValues =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | CustomFieldCategoricalSingle
  | CustomFieldCategoricalMultiple;

export interface CustomFieldProps {
  [key: string]: CustomFieldValues;
}

export class CustomFields extends ValueObject<CustomFieldProps> {
  private definitions: CustomFieldDefinition[];

  get value(): CustomFieldProps {
    return this.props;
  }

  getDefinition(fieldCustomId: string): CustomFieldDefinition | undefined {
    return this.definitions.find((def) => def.custom_id === fieldCustomId);
  }

  private constructor(props: CustomFieldProps, definitions: CustomFieldDefinition[]) {
    super(props);
    this.definitions = definitions;
  }

  static create(props: CustomFieldProps = {}, definitions: CustomFieldDefinition[] = []): CustomFields {
    return new CustomFields(props, definitions);
  }

  update(newProps: Record<string, any>) {
    Object.entries(newProps).forEach((prop) => {
      if (prop[1] === null) {
        if (!this.props[prop[0]]) {
          return;
        }
        delete this.props[prop[0]];
      }
      this.props[prop[0]] = prop[1];
    });
  }
}
