import { Close as CloseIcon } from '@mui/icons-material';
import { Input, InputBaseComponentProps } from '@mui/material';
import { validateEmail } from '@nx-smartmonkey/shared/helpers';
import React, { useEffect, useState } from 'react';

import { RoutalPalette } from './Colors';
import { RoutalText } from './RoutalText';

export type InputType = `number` | `url` | `integer` | `text` | `email` | `password`;

export type InputSize = `small` | `medium` | `large` | `title`;

export type InputVariant = `flat` | `outlined` | `clean`;

export interface RoutalInputTextProps {
  id?: string;
  type?: InputType;
  size?: InputSize;
  required?: boolean;
  tabIndex?: number;
  fieldValue?: string | number;
  suffix?: string;
  label?: string | React.ReactElement;
  variant?: InputVariant;
  disabled?: boolean;
  focused?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  rowsMin?: number;
  rowsMax?: number;
  inputStyles?: React.CSSProperties;
  inputContainerStyles?: React.CSSProperties;
  hoverBGColor?: string;
  bGColor?: string;
  forceFocusedBorderColor?: boolean;
  focusedBorderColor?: string;
  inputProps?: InputBaseComponentProps;
  placeholder?: string;
  validators?: {
    errorMessage: string | React.ReactElement;
    isInvalid: (text?: string) => boolean;
  }[];
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  disableEnter?: boolean;
  onClickStopPropagation?: boolean;
  handleEnter?: (...args: any[]) => any;
  onChange?: (value: any) => void;
  onDelete?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  onKeyDown?: (...args: any[]) => any;
}

type Styles = `inputContainer` | `input` | `closeIcon` | `suffix`;

export const computeInputTextStyles = ({
  size,
  multiline,
  variant,
  hover,
  inputFocused,
  inputStyles,
  inputContainerStyles,
  hoverBGColor,
  bGColor,
  forceFocusedBorderColor,
  focusedBorderColor = RoutalPalette.primary00,
}: {
  size?: InputSize;
  multiline?: boolean;
  variant?: InputVariant;
  hover?: boolean;
  inputFocused?: boolean;
  inputStyles?: React.CSSProperties;
  inputContainerStyles?: React.CSSProperties;
  hoverBGColor?: string;
  bGColor?: string;
  forceFocusedBorderColor?: boolean;
  focusedBorderColor?: string;
}): Record<Styles, React.CSSProperties> => {
  const computedStyles: Record<Styles, React.CSSProperties> = {
    inputContainer: {
      height: multiline ? `auto` : `36px`,
      cursor: `pointer`,
      borderRadius: `5px`,

      ...(inputContainerStyles ?? {}),
      ...(hover && !inputFocused
        ? {
            backgroundColor: hoverBGColor ?? RoutalPalette.neutral05,
          }
        : {
            backgroundColor: bGColor ?? RoutalPalette.neutral00,
          }),
    },
    input: {
      ...(inputStyles ?? {}),
      cursor: `pointer`,
      minHeight: `28px`,
    },
    closeIcon: {
      cursor: `pointer`,
      width: `20px`,
      height: `20px`,
      marginRight: `4px`,
      color: RoutalPalette.grey11,
    },
    suffix: {
      marginRight: `40px`,
      color: RoutalPalette.grey7,
    },
  };

  switch (variant) {
    case `clean`:
      if (inputFocused) {
        if (!inputContainerStyles?.border || forceFocusedBorderColor) {
          computedStyles.inputContainer.border = `1px solid ${focusedBorderColor}`;
        }
      }
      break;
    case `flat`:
      if (!inputContainerStyles?.border || forceFocusedBorderColor) {
        computedStyles.inputContainer.borderBottomRightRadius = `0`;
        computedStyles.inputContainer.borderBottomLeftRadius = `0`;
        computedStyles.inputContainer.border = `none`;
        computedStyles.inputContainer.borderBottom = inputFocused
          ? `1px solid ${focusedBorderColor}`
          : `1px solid ${RoutalPalette.neutral20}`;
      }
      break;
    case `outlined`:
      if (!inputContainerStyles?.border || forceFocusedBorderColor) {
        // computedStyles.inputContainer.borderRadius = `4px`;
        computedStyles.inputContainer.border = inputFocused
          ? `1px solid ${focusedBorderColor}`
          : `1px solid ${RoutalPalette.neutral20}`;
      }
      break;
  }

  switch (size) {
    case `large`:
      computedStyles.input.fontSize = `16px`;
      computedStyles.input.padding = `80px`;
      computedStyles.inputContainer.height = `46px`;
      break;
    case `small`:
      computedStyles.input.fontSize = `9px`;
      computedStyles.input.padding = `2px 8px`;
      break;
    case `title`:
      computedStyles.input.fontSize = `20px`;
      computedStyles.input.fontWeight = 600;
      computedStyles.input.padding = `4px 8px`;
      break;
    case `medium`:
    default:
      computedStyles.input.fontSize = `13px`;
      computedStyles.input.padding = `4px 8px`;
      break;
  }

  return computedStyles;
};

export const RoutalInputText = ({
  id,
  type = `text`,
  size = `medium`,
  required = false,
  tabIndex,
  fieldValue,
  suffix,
  label,
  variant = `outlined`,
  disabled = false,
  focused,
  autoFocus,
  fullWidth,
  multiline,
  rowsMin,
  rowsMax,
  inputStyles,
  inputContainerStyles,
  hoverBGColor,
  bGColor,
  forceFocusedBorderColor,
  focusedBorderColor,
  inputProps,
  placeholder,
  disableEnter,
  onClickStopPropagation,
  handleEnter,
  onChange,
  onDelete,
  onBlur,
  onFocus,
  onKeyDown,
  validators,
  startAdornment,
  endAdornment,
}: RoutalInputTextProps) => {
  const [value, setValue] = useState(fieldValue);
  const [errorMessage, setErrorMessage] = useState<string | React.ReactElement>(``);
  const [inputFocused, setInputFocused] = useState(focused ?? false);
  const [hover, setHover] = useState(false);

  const hasChanged = () => {
    return value !== fieldValue;
  };

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (inputFocused && onFocus) {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocused]);

  /**
   * This function is required for validating the component inside
   * a form.
   */
  const validate = (newValue: string): boolean => {
    const errors =
      validators
        ?.map((element: { errorMessage: string | React.ReactElement; isInvalid: (text?: string) => boolean }) => {
          return element.isInvalid(newValue) ? element.errorMessage : ``;
        })
        .filter((error: any) => error !== ``) ?? [];

    setErrorMessage(errors.length > 0 ? errors[0] : ``);

    return errors.length === 0;
  };

  const handleChange = (event: any) => {
    let newValue = event.target.value;
    if (type === `number` || type === `integer`) {
      if (type === `number`) {
        newValue = Number.parseFloat(`${newValue}`);
      }
      if (type === `integer`) {
        newValue = Number.parseInt(`${newValue}`, 10);
      }
      if (Number.isNaN(newValue)) {
        newValue = null;
      }
    }

    setValue(newValue);
    validate(newValue);

    if (onChange) onChange(newValue);
  };

  const handleUpdate = async () => {
    if (handleEnter && hasChanged()) {
      if (type === `number` || type === `integer`) {
        handleEnter(value);
      } else if (type === `email`) {
        handleEnter(value && validateEmail(`${value}`) ? `${value}` : null);
      } else {
        handleEnter(value ? `${value}` : null);
      }
    }
  };

  const handleKeyPress = async (event: any) => {
    switch (event.key) {
      case `Enter`:
        if (disableEnter) break;
        setInputFocused(false);
        await handleUpdate();

        break;
      case `Escape`:
        setInputFocused(false);
        setValue(fieldValue);
        break;
      default:
        break;
    }

    if (onKeyDown) onKeyDown(event);
  };

  const handleBlur = async (event: any) => {
    await handleUpdate();
    setInputFocused(false);
    if (onBlur) onBlur(event);
  };

  const handleFocus = () => {
    setInputFocused(true);
  };

  const computedStyles = computeInputTextStyles({
    size,
    multiline,
    variant,
    hover,
    inputFocused,
    inputContainerStyles,
    inputStyles,
    hoverBGColor,
    bGColor,
    forceFocusedBorderColor,
    focusedBorderColor,
  });

  const { style: inputPropsStyle, ...otherInputProps } = inputProps ?? {};
  return (
    <>
      {label ? (
        <RoutalText tagName="span" style={{ marginBottom: `4px` }}>
          <>
            {label}
            {required ? (
              <RoutalText
                tagName="span"
                variant="body"
                style={{ cursor: `default`, color: RoutalPalette.error.medium }}
              >
                *
              </RoutalText>
            ) : null}
          </>
        </RoutalText>
      ) : null}
      <Input
        id={id}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        tabIndex={tabIndex}
        onKeyDown={handleKeyPress}
        onBlur={handleBlur}
        value={value ?? ``}
        onChange={handleChange}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        {...(onClickStopPropagation
          ? {
              onClick: (e: any) => {
                e.stopPropagation();
              },
            }
          : {})}
        type={type === `integer` ? `number` : type}
        disabled={disabled}
        multiline={multiline}
        maxRows={rowsMax ?? rowsMin ?? 2}
        minRows={rowsMin ?? 2}
        disableUnderline
        placeholder={placeholder}
        style={computedStyles.inputContainer}
        inputProps={{
          style: {
            ...computedStyles.input,
            ...(inputPropsStyle ?? {}),
            cursor: disabled ? `default` : `pointer`,
          },
          ...(type === `integer` ? { step: `1` } : {}),
          ...otherInputProps,
        }}
        startAdornment={startAdornment}
        endAdornment={
          endAdornment ? (
            endAdornment
          ) : (onDelete && fieldValue && fieldValue !== ``) || (suffix && (!!value || value === 0)) ? (
            <>
              {suffix && (!!value || value === 0) ? <span style={computedStyles.suffix}>{suffix}</span> : null}
              {onDelete && fieldValue && fieldValue !== `` ? (
                <CloseIcon
                  style={computedStyles.closeIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              ) : null}
            </>
          ) : null
        }
      />
      {errorMessage && errorMessage !== `` ? (
        <RoutalText variant="body" style={{ cursor: `default`, color: RoutalPalette.error.medium, marginTop: `4px` }}>
          {errorMessage}
        </RoutalText>
      ) : null}
    </>
  );
};
