import { Language } from '@nx-smartmonkey/shared/domain';
import { IntlShape } from 'react-intl';
import { TranslationsActionType } from '../../context/translations/actions';
import { useTranslationsContext } from '../../context/translations/context';

type UseSetActiveLanguageProps = {
  language: Language;
};

type UseSetActiveLanguageResponse = {
  run: ({ language }: UseSetActiveLanguageProps) => void;
  data?: IntlShape;
};

// Hooks act as 'Adapter' layer.
export const useSetActiveLanguage = (): UseSetActiveLanguageResponse => {
  const { translationsState, translationsDispatch } = useTranslationsContext();
  return {
    run: ({ language }: UseSetActiveLanguageProps) => {
      translationsDispatch({ type: TranslationsActionType.SET_ACTIVE_LANGUAGE, payload: { language } });
    },
    data: translationsState.activeLanguage,
  };
};
