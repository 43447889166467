import { Grid } from '@mui/material';
import { Language } from '@nx-smartmonkey/shared/domain';
import { HSpacer } from '@nx-smartmonkey/ui';

import { useEffect, useState } from 'react';
import { SearchPredicate } from '../../domain/searchPredicates/SearchPredicate';
import { useSearchStopsPredicates } from '../../hooks/stop/useSearchStopsPredicates';
import { useRetrieveSupervisor } from '../../hooks/supervisor/useRetrieveSupervisor';
import { getAvailableOptions } from './SearchFiltersAvailableOptions';
import { SearchFiltersButton } from './SearchFiltersButton';
import { SearchFiltersList } from './SearchFiltersList';
import { SearchFilterBoolean } from './boolean';
import { SearchFilterBooleanOperator } from './boolean/SearchFilterBoolean';
import { SearchFilterDate } from './date';
import { SearchFilterDateOperator, SearchFilterDateValue } from './date/SearchFilterDate';
import { SearchFilterDateTime } from './date_time';
import { SearchFilterDateTimeOperator, SearchFilterDateTimeValue } from './date_time/SearchFilterDateTime';
import { SearchFilterList } from './list';
import { SearchFilterListOperator, SearchFilterListOptionProps, SearchFilterListValue } from './list/SearchFilterList';
import { SearchFilterNumber } from './number';
import { SearchFilterNumberOperator } from './number/SearchFilterNumber';
import { SearchFilterString } from './string';
import { SearchFilterStringOperator, SearchFilterStringValue } from './string/SearchFilterString';
import { SearchFilterSurvey } from './survey';
import { SearchFilterSurveyOperator, SearchFilterSurveyValue } from './survey/SearchFilterSurvey';
import { SearchFilterTime } from './time';
import { SearchFilterTimeOperator, SearchFilterTimeValue } from './time/SearchFilterTime';

interface SearchFiltersProps {
  language?: Language;
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
}

export const SearchFilters = ({ language, colors }: SearchFiltersProps) => {
  const { data: supervisor } = useRetrieveSupervisor();
  const { predicates, setPredicates } = useSearchStopsPredicates();
  const [openLast, setOpenLast] = useState<boolean>(false);

  const availableOptions = getAvailableOptions(supervisor);
  const availableOptionsMap = availableOptions.reduce((acc, option) => {
    acc[option.field] = option;
    return acc;
  }, {} as Record<string, (typeof availableOptions)[number]>);

  const filters: Array<
    | SearchFilterString
    | SearchFilterBoolean
    | SearchFilterNumber
    | SearchFilterSurvey
    | SearchFilterDate
    | SearchFilterList
  > = predicates
    .filter((predicate) => {
      return availableOptionsMap[predicate.field];
    })
    .map((predicate) => {
      const option = availableOptionsMap[predicate.field];
      switch (option.type) {
        case `boolean`:
          return new SearchFilterBoolean({
            field: option.field,
            type: `boolean`,
            value: null,
            operator: predicate.operator as SearchFilterBooleanOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
        case `number`:
          return new SearchFilterNumber({
            field: option.field,
            type: `number`,
            value: predicate.value as number,
            operator: predicate.operator as SearchFilterNumberOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
        case `date`:
          return new SearchFilterDate({
            field: option.field,
            type: `date`,
            value: predicate.value as SearchFilterDateValue,
            operator: predicate.operator as SearchFilterDateOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
        case `list`:
          return new SearchFilterList({
            field: option.field,
            type: `list`,
            value: predicate.value as SearchFilterListValue,
            operator: predicate.operator as SearchFilterListOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
            listOptions: option.listOptions as SearchFilterListOptionProps[],
          });
        case `survey`:
          return new SearchFilterSurvey({
            field: option.field,
            type: `survey`,
            value: predicate.value as SearchFilterSurveyValue,
            operator: predicate.operator as SearchFilterSurveyOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
        case `date_time`:
          return new SearchFilterDateTime({
            field: option.field,
            type: `date_time`,
            value: predicate.value as SearchFilterDateTimeValue,
            operator: predicate.operator as SearchFilterDateTimeOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
        case `time`:
          return new SearchFilterTime({
            field: option.field,
            type: `time`,
            value: predicate.value as SearchFilterTimeValue,
            operator: predicate.operator as SearchFilterTimeOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
        case `string`:
        default:
          return new SearchFilterString({
            field: option.field,
            type: `string`,
            value: predicate.value as SearchFilterStringValue,
            operator: predicate.operator as SearchFilterStringOperator,
            iconSrc: option.iconSrc,
            formatted: option.formatted,
            computed: option.computed,
          });
      }
    });

  useEffect(() => {
    if (openLast) {
      document.getElementById(`filter-${filters[filters.length - 1]?.getType()}-${filters.length - 1}`)?.click();
      setOpenLast(false);
    }
  }, [filters, openLast]);

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item>
        <SearchFiltersButton
          onAddFilter={(filter) => {
            const newPredicates = [
              ...predicates,
              SearchPredicate.create({
                field: filter.getField(),
                operator: filter.getOperator(),
                value: filter.getValue(),
                type: filter.getType(),
              }),
            ];
            setPredicates(newPredicates);
            setOpenLast(true);
          }}
        />
      </Grid>

      <HSpacer small />

      <SearchFiltersList
        filters={filters}
        language={language}
        colors={colors}
        onChangeFilter={(filter, idx) => {
          setPredicates(
            predicates.map((predicate, i) => {
              if (i === idx) {
                return SearchPredicate.create({
                  field: filter.getField(),
                  operator: filter.getOperator(),
                  value: filter.getValue(),
                  type: filter.getType(),
                });
              }
              return predicate;
            })
          );
        }}
        onRemoveFilter={(idx: number) => {
          setPredicates(predicates.filter((_, i) => i !== idx));
        }}
      />
    </Grid>
  );
};
